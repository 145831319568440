import { useRef, useState } from "react";

let keyTextRotate = '';

function Key({note})
{
    const noteIsFlat = note.length > 2;
    const audioNotesRef = useRef();

    const [playCounter, setPlayCounter] = useState(0);

    function playNote()
    {
        if(audioNotesRef.current)
        {
            audioNotesRef.current.play();
            setPlayCounter(playCounter+1)
        }

        if(playCounter >= 1)
        {
            audioNotesRef.current.currentTime = 0;
        }
    }

    if(noteIsFlat)
    {
        return(
            <button className="key-piano-flat" onClick={playNote}>
                <audio
                    id={note}
                    key={note}
                    src={`../../mus_notes/${note}.mp3`}
                    ref={audioNotesRef}
                />
            </button>
        )
    }
    else
    {
        return(
            <button className="key-piano" onClick={playNote}>
                <div className={`key-piano__text ${keyTextRotate}`}>
                    {note.substring(0,1)}<sub>{note.substring(1)}</sub>
                </div>
                <audio
                    id={note}
                    key={note}
                    src={`../../mus_notes/${note}.mp3`}
                    ref={audioNotesRef}
                />
            </button>
        )
    }
}

export default Key