
import { useEffect, useRef, forwardRef } from "react";

const LIST_LETTER_HEADER_MAXLEN = 1;

const UList = forwardRef(({listToRender, onItemClick}, arrRef) => {
  function addRef(elem)
  {
    if(elem){
      arrRef.current.push(elem)
    }
  }
  return(
    <ul className="list__items">
    {
        listToRender.map((song, index) => {
            if(song.titlu.length > LIST_LETTER_HEADER_MAXLEN)
            {
              return(
                <li key={song.titlu} 
                    onClick = {() => onItemClick(song, index)}
                    className="list__item"
                >
                    {song.titlu}
                </li>
              )
            }
            else
            {
              return(
                <li 
                    key={song.titlu} 
                    id = {song.titlu} 
                    ref = {(elem) => addRef(elem)}
                    className = 'list__letter-header'
                >
                        {song.titlu}
                </li>
              )
            }  
        })
    }
    </ul>
  )
})

export default UList ;