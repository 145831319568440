import { CgMenuGridR } from "react-icons/cg";
import { GiCardExchange, GiReturnArrow } from "react-icons/gi";
import { FaSearchengin, FaArrowUpWideShort, FaAngleRight } from 'react-icons/fa6'
import { TbTextDecrease, TbTextIncrease } from "react-icons/tb";

import { useNavigate, useLocation } from "react-router-dom";
import { useContext, useRef, useState } from "react";
import { CorContext } from "../../pages/Cor/cor-context";

import { PiDotsThreeOutlineVerticalLight } from "react-icons/pi";
import { TiHomeOutline, TiPlus, TiMinus } from "react-icons/ti";
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai";
import { BsPlusCircleDotted, BsDashCircleDotted } from "react-icons/bs";
import { IoNavigate } from "react-icons/io5";
import { MdMapsHomeWork, MdOutlineHomeWork, MdOutlineTextIncrease, MdOutlineTextDecrease } from "react-icons/md";
import { RiClockwiseFill } from "react-icons/ri";

let auxElements = null;
let backPath = null;

function Navbar({navType, ...optParams})
{
    const navigate = useNavigate();
    const location = useLocation();
    const [switchSongState, setSwitchSongState] = useState(false)
    const songInput = useRef();
    const voceCorRef = useRef();

    const { setVoceCor, scoreControls, setScoreControls } = useContext(CorContext)

    const locState = location.state

    const listType = locState ? locState.listType : 'Secțiuni';
    const section = locState ? locState.section : 'S';
    navType = navType.toUpperCase();

    function handleScrollToTop()
    {
        document.getElementById('S').scrollIntoView(true);
    }

    function handleSearch()
    {
        
        optParams.handleSearchBar(!optParams.searchBarState)
    }

    function handleSwitchSong()
    {
      let selectedSongSection = songInput.current.value.split('.')[0];
      let completeSection;

      if(Number(selectedSongSection) < 10){
        completeSection = 'S0'+ selectedSongSection
      }else{
        completeSection = 'S'+ selectedSongSection
      }

        setSwitchSongState(false)
        navigate(`../lyrics/${songInput.current.value}`, {
            state: {
                listType: listType,
                section: completeSection
            }
        })
    }

    function handleVoceCor(ev)
    {
        setVoceCor(ev.target.value)
    }

    if(navType != 'LYRICS')
    {
        switch(navType)
        {
            case 'ALFABETIC':
            case 'REPETIȚII':
                auxElements =
                <>
                    <span 
                        className='navbar__text' 
                        onClick={() => optParams.setListTypeDrpdwnActive(!optParams.listTypeDrpdwnActive)}
                    >
                        {navType.toUpperCase()}
                    </span>
                </>
                backPath = '/';
            break;
            
            case 'AUDIO':
            case 'VIDEO':
                auxElements =
                <>
                    <span 
                        className='navbar__text' 
                        onClick={() => optParams.setAudioList(!optParams.audioList)}
                    >
                        {navType}
                    </span>
                </>
                backPath = '/';
            break;

            case 'SECȚIUNI':
                auxElements = 
                <>
                    <button
                        type="button"
                        className='navbar__button gen__svg-alignment'
                        onClick={handleScrollToTop}
                    >
                        <FaArrowUpWideShort   />
                    </button>
                    <span 
                        className='navbar__text' 
                        onClick={() => optParams.setListTypeDrpdwnActive(!optParams.listTypeDrpdwnActive)}
                    >
                        {navType}
                    </span>
                </>
                backPath = '/';
            break;
    
            case 'COR':
                auxElements = 
                    <>
                        {   !scoreControls.controlMenuActive
                            ?   <button
                                    type="button"
                                    className = 'navbar__button gen__svg-alignment'
                                    onClick = {() => setScoreControls({
                                        ...scoreControls, 
                                        controlMenuActive: true
                                    })}
                                    >
                                        <PiDotsThreeOutlineVerticalLight />
                                </button>
                            :   <button
                                    type="button"
                                    className = 'navbar__button gen__svg-alignment red'
                                    onClick = {() => setScoreControls({
                                        ...scoreControls, 
                                        controlMenuActive: false,
                                        pianoActive: false,
                                        scoreActive: false,
                                        scoreRotationActive: false
                                    })}
                                    >
                                        <RiClockwiseFill />
                                </button>
                        }
                        
                        <select
                            className="navbar__cor-sel-voice"
                            id = "vociCor"
                            onChange = {handleVoceCor}
                            ref={voceCorRef}
                        >
                            <option value='s'>Soprano</option>
                            <option value='a'>Alto</option>
                            <option value='t'>Tenor</option>
                            <option value='b'>Bas</option>
                            <option value='cv'>Cvartet</option>
                        </select>
                        <span 
                            className='navbar__text'
                            style = {{border: 'none'}}
                        >
                                {navType}
                        </span>
                    </>
                backPath = '/';
            break;

            default:
                break;
        }

        return(
            <nav className="navbar">
                <button 
                    type="button"
                    id="navbar__home-btn"
                    className="gen__svg-alignment"
                    onClick={() => navigate('/')}
                >
                    <MdOutlineHomeWork  />
                </button>
               
                <button
                    type="button"
                    className='navbar__button gen__svg-alignment'
                    onClick={() => navigate(backPath)}
                >
                    <GiReturnArrow  />
                </button>
                {
                    (navType == 'ALFABETIC' || navType == 'SECȚIUNI')
                    ?   <button
                            type="button"
                            className='navbar__button gen__svg-alignment'
                        >
                            <FaSearchengin onClick={handleSearch}/>
                        </button>
                    : <></>
                }
                {auxElements}              
            </nav>
        )
    }
    else // -=>  LYRICS NAV  <=- //
    {
        return(
            <nav className="navbar">
                <button
                    type="button"
                    id="navbar__home-btn"
                    className='gen__svg-alignment'
                    onClick={() => navigate('/')}
                >
                    <MdOutlineHomeWork />
                </button>
                <button
                    type="button"
                    className='navbar__button gen__svg-alignment'
                    onClick={() => navigate(`/text`, {
                        state: {
                            listType: listType,
                            section: section
                        }
                    })}
                >
                    <GiReturnArrow  />
                </button>
                <button
                    type="button"
                    className='navbar__button gen__svg-alignment'
                    onClick={() => optParams.changeFontSize('decrease')}
                >
                    <BsDashCircleDotted  />
                </button>
                <button
                    type="button"
                    className='navbar__button gen__svg-alignment'
                    onClick={() => optParams.changeFontSize('increase')}
                >
                    <BsPlusCircleDotted  />
                </button>
                <button
                    type="button"
                    className='navbar__button gen__svg-alignment'
                    onClick={() => setSwitchSongState(!switchSongState)}
                >
                    <FaSearchengin  />
                </button>
                {
                    switchSongState
                    ?  <div className="navbar__switch-song">
                            <input type="text" ref={songInput} className="navbar__song-input"/>
                            <button
                                type="button"
                                className='navbar__open-song-btn gen__svg-alignment'
                                onClick={handleSwitchSong}
                            >
                                <IoNavigate  />
                            </button>
                        </div>
                    :  <></>
                }
                
                {/* <div id='navbar__open-song'>
                    <input 
                        type = "text" 
                        id="navbar__song-to-go" 
                        placeholder='nr..' 
                        ref={songInput}
                    />
                    <button 
                        type="button" 
                        className='svg-alignment'
                        id='navbar__go-to-song'
                        onClick={() => navigate('/text/lyrics/' + songInput.current.value)}
                    >
                        <FaAngleRight />
                    </button>
                </div> */}
            </nav>
        )
    }
}

export default Navbar