
import './scss/main.scss'
import { Routes, Route } from 'react-router-dom' 
import Arhiva from './pages/Arhivă/Arhivă';
import Cor from './pages/Cor/Cor'
// import LevitiiLucratori from './comps/LevitiiLucratori';
import InstrumenteMenu from './pages/InstrumenteMenu/InstrumenteMenu'
import InstrumentWrapper from './comps/instruments/InstrumentWrapper';
import MainSections from './pages/_MainSections/MainSections'
import Lyrics from './pages/Lyrics/Lyrics';
import SongsList from './pages/SongsList/SongsList';

function App() {
  return (
      <Routes>
        <Route path='/'                      
          element={<MainSections />} 
        />
        <Route path='text'                   
          element={<SongsList />} 
        />
        {/* <Route 
          path='instr'                  
          element={<InstrumenteMenu />}
        /> */}
        {/* <Route path='instr/:type'            
          element={<InstrumentWrapper />}
        /> */}
        <Route path='cor'                    
          element={<Cor />}
        />
        <Route path='arhiva'                 
          element={<Arhiva />}
        />
        <Route path='lyrics/:songNbr'        
          element={<Lyrics />}
        />
        {/* <Route path='leviluc'                
          element={<LevitiiLucratori />}
        /> */}
      </Routes>
    );
}

export default App;