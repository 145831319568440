import React, { Component, Fragment, useEffect, useState, useRef } from 'react'

import { useParams } from 'react-router-dom';

import IndicatiiParcurgere from './chapters/IndicatiiParcurgere';
import NoteleMuzicale from './chapters/NoteleMuzicale';
import AcordurileMuzicale from './chapters/AcordurileMuzicale';
import GameleMuzicale from './chapters/GameleMuzicale'
import Gama from './chapters/Gama';

const INSTR_CHAPTERS = [
  'Indicații de parcurgere',
  '1. Studiul notelor muzicale',
  '2. Studiul acordurilor muzicale',
  '3. Studiul gamelor muzicale',
  '4. Gama Do Major',
  '5. Gama Re Major',
  '6. Gama Mi Major',
  '7. Gama Fa Major',
  '8. Gama Sol Major',
  '9. Gama La Major',
  '10. Gama Si Major',
  '11. Gama Do minor',
  '12. Gama Re minor',
  '13. Gama Mi minor',
  '14. Gama Fa minor',
  '15. Gama Sol minor',
  '16. Gama La minor',
  '17. Gama Si minor',
  // '18. Anexa 1. Cântări suplimentare pe acorduri',
  // '19. Anexa 2. Cântări suplimentare pe note',
  // '20. Anexa 3. Tabel denumiri internaționale acorduri'
]

const CHAPTER_ID = INSTR_CHAPTERS.map((chapter, idx) => idx <= 9 ? chapter.substring(0,1) : chapter.substring(0,2))

const CHAPTERS_CONTENT_STATES = { 
    I: false, 1: false, 2: false, 3: false, 4: false, 5: false, 
    6: false, 7: false, 8: false, 9: false, 10: false, 11: false,
    12: false, 13: false, 14: false, 15: false, 16: false, 17: false
}

function InstrumentWrapper() 
{
    const { type } = useParams();

    const CHAPTER_CONTENT = {
        I: <IndicatiiParcurgere instrument={type} />,
        1: <NoteleMuzicale instrument={type} />,
        2: <AcordurileMuzicale instrument={type} />,
        3: <GameleMuzicale instrument={type} />,
        4: <Gama range={'C'} />,
        10: <Gama range={'B'} />,
        16: <Gama range={'a'} />
    }

    const [chapterActiveState, setChapterActiveState] = useState([]);
    const [isChapterActive, setIsChapterActive] = useState(false);

    useEffect(() => 
    {
        setChapterActiveState(CHAPTERS_CONTENT_STATES)
    }, [])

    function handleChapterClick(e)
    {
        chapterActiveState[e.target.id] = !chapterActiveState[e.target.id];
        setChapterActiveState(chapterActiveState)
        if(chapterActiveState[e.target.id] == true)
        {
            setIsChapterActive(!isChapterActive);
        }
    }

    return (
        <>
            <main className='instrument__container'>
                <section className='instrument__cover-page'> 
                    <h1 className='instrument__title'>LECȚII DE</h1>
                    <img 
                        className="instrument__img" 
                        src = {`../img/instrumente/${type}.jpg`} 
                        alt = "Image not found!"
                    />
                    <h1 className='instrument__title'>pentru <br/>ÎNCEPĂTORI</h1>
                </section> 

                <video controls className="instrument__video-player">
                    <source src="../../../video/instrumente/introducere.mp4" type="video/mp4" />
                </video>

                <section className='instrument__chapters'>
                    {INSTR_CHAPTERS.map((chapter, index) => {
                        return(
                            <details>
                                <summary
                                    key={chapter.substring(0,1)}
                                    className='instrument__chapter-button' 
                                    onClick={handleChapterClick}
                                    id={chapter.substring(0,1)}
                                >
                                    {chapter}
                                </summary>
                                <div className='instrument__chapter-content'>
                                    {CHAPTER_CONTENT[CHAPTER_ID[index]]}
                                </div>                       
                            </details>
                        )
                    })}
                </section>
            </main>
        </>
    )
}

export default InstrumentWrapper;
