import { useState, useEffect } from "react";
import axios from "axios";
import fileDownload from "js-file-download";
import { Link } from "react-router-dom";
import { useOrientation } from "@uidotdev/usehooks";

import UList from "../../components/UList/UList";
import Navbar from "../../components/Navbar/Navbar";
import CustomAudioPlayer from "../../components/CustomAudioPlayer/CustomAudioPlayer";
import { CorContext } from "./cor-context";
import ScoreIcon from "../../components/ScoreIcon/ScoreIcon";
import Piano from "../../components/Piano/Piano";

import { MdPiano, MdOutlineCropRotate } from "react-icons/md";
import { BsEmojiSmile } from 'react-icons/bs'
import { FaFileDownload, FaEyeSlash, FaEye } from "react-icons/fa";
import { PiFileAudioFill } from "react-icons/pi";
import { VscPiano } from "react-icons/vsc";
import { CiRead, CiUnread } from "react-icons/ci";

const VOCI_COR_TEXT = {
    's'  : 'soprano', 
    'a'  : 'alto', 
    't'  : 'tenor', 
    'b'  : 'bas', 
    'cv' : 'cvartet'
}

function Cor()
{
    const [corSongs, setCorSongs] = useState([])
    const [deRepetat, setDeRepetat] = useState([])
    const [dateOfUpdate, setDateOfUpdate] = useState('')
    const [currentSong, setCurrentSong] = useState({
        index: 0,
        nr: '1',
        titlu: '1 - Tu, popor ales',
        gama: 'Fa m'
    });
    const [voceCor, setVoceCor] = useState('s')
    const [songToLoad, setSongToLoad] = useState('audio_cor/1_s.mp3')
    const [scoreControls, setScoreControls] = useState({
        controlMenuActive: false,
        pianoActive: false,
        scoreActive: false,
        scoreRotationActive: false
    })
    const screenOrientation = useOrientation();
    const [scoreLndscpCondition, setScoreLndscpCondition] = useState(false)
    //let scoreLndscpCondition = false;

    useEffect(() => {
        if(screenOrientation.type == 'landscape-primary' || screenOrientation.type == 'landscape-secondary'){
            setScoreControls({...scoreControls, controlMenuActive: true, scoreActive: true})
            setScoreLndscpCondition(true)
        }
        else{
            setScoreControls({...scoreControls, controlMenuActive: false, scoreActive: false})
            setScoreLndscpCondition(false)
        }
    }, [screenOrientation])

    useEffect(() =>
    {
      axios.get("../../data/cor_data.json")
      .then((response) => {
            let length = response.data.length;
            let songs = response.data.slice(1,length).map((item, index) => 
            {
                return {
                    index: index,
                    nr: item.nr,
                    titlu: item.nr + ' - ' + item.titlu,
                    gama: item.gama
                }
            });

            setDeRepetat(response.data[0].deRepetat);
            setDateOfUpdate(response.data[0].dateOfUpdate);
            setCorSongs(songs);
      })
      .catch(error  => {
          console.log(error)
      })
    },[])

    useEffect(() => {
        selectCorSong(currentSong.index)
    }, [voceCor])

    function selectCorSong(...args)
    {
        if(args.length == 1)
        {
            if(args[0] >= 0 && args[0] < corSongs.length)
            {
                const songToSelect = corSongs[args[0]]
                setCurrentSong(songToSelect)
                setSongToLoad(`audio_cor/${songToSelect.nr}_${voceCor}.mp3`)
            }
        }
        else
        {
            const songToSelect = corSongs[args[1]]
            setCurrentSong(songToSelect)
            setSongToLoad(`audio_cor/${songToSelect.nr}_${voceCor}.mp3`)

        }
    }

    function handleScoreDWLD(url, filename)
    {
        console.log(url, filename)
        axios.get(url, {
            responseType: 'blob'
        })
        .then((res) => {
            console.log(res.data)
            fileDownload(res.data, filename)
        })
    }

    function onScoreClose()
    {
        setScoreControls({
            ...scoreControls, 
            scoreActive: false, 
            pianoActive: false,
            scoreRotationActive: false
        })
    }

    return(
        <main className="cor">
            {
                !scoreControls.controlMenuActive
                ?  <div className="cor__info">
                    <h3 className="cor__info-title">DE REPETAT</h3>
                    <div>
                    {
                        deRepetat.map(item => {
                            return(
                                <span key={item} className="cor__info-song">
                                    {item}
                                </span>
                            )
                        })
                    }
                    </div>
                    <span className='cor__info-DOU'>{dateOfUpdate}</span>
                    </div>
                : <></>
            }
            {/* {
                scoreControls.scoreActive
                ?   <section 
                        className='cor__score-area' 
                        style={scoreControls.scoreRotationActive ? {'--rotation':'-90deg'} : {'--rotation' : '0deg'} }
                    >
                        <img src={'partituri_img/' + currentSong.nr + '_pgn.jpg'} 
                            alt="Partitura nu a fost găsită!"
                            className='img-normal'>
                        </img> 
                    </section>
                :   <UList 
                        listToRender={corSongs} 
                        onItemClick={selectCorSong}
                    />
            } */}

            {
                scoreControls.scoreActive
                ?    
                    !scoreControls.scoreRotationActive
                    ? <section className='cor__score-area' >
                        <img src={'partituri_img/' + currentSong.nr + '_pgn.jpg'} 
                            alt="Partitura nu a fost găsită!"
                            className='img-normal'>
                        </img> 
                    </section>
                    : <section className='cor__score-area' >
                        <img src={'partituri_img/' + currentSong.nr + '_pgr.jpg'} 
                            alt="Partitura nu a fost găsită!"
                            className='img-rotated'>
                        </img> 
                    </section>
                :   <UList 
                        listToRender={corSongs} 
                        onItemClick={selectCorSong}
                    />
            }
            <section className="cor__tools">
                {
                    scoreControls.controlMenuActive && !scoreLndscpCondition
                    ? <div className='cor__score-control'>
                        <button type="button" className="cor__audio-dwld gen__svg-alignment">                    
                            <a href={`audio_cor/${currentSong.nr}_${voceCor}.mp3`} 
                               download={`${currentSong.titlu}_${VOCI_COR_TEXT[voceCor]}`}
                               className="gen__svg-alignment"
                            >
                                    <PiFileAudioFill />
                            </a>
                        </button>
                        <button 
                            type="button" 
                            className='cor__score-download'>
                                <a href={`partituri_pdf/${currentSong.nr}_pg.pdf`} 
                                   download={`${currentSong.titlu}_partitura`}
                                   className="gen__svg-alignment"
                                >
                                      <FaFileDownload />
                                </a>
                        </button>
                        {
                            scoreControls.scoreActive                          
                            ? <>
                                <button 
                                    type="button" 
                                    className='cor__piano gen__svg-alignment'
                                    onClick={() => setScoreControls({...scoreControls, pianoActive: !scoreControls.pianoActive})}
                                >
                                    <VscPiano />
                                </button>
                                <button 
                                    type="button" 
                                    className='cor__score-show gen__svg-alignment'
                                    onClick={onScoreClose}
                                >
                                    <ScoreIcon eyeIcon={< CiUnread />} /> 
                                </button>
                                <button 
                                    type="button" 
                                    className='cor__score-rotate gen__svg-alignment'
                                    onClick={() => setScoreControls({...scoreControls, scoreRotationActive: !scoreControls.scoreRotationActive})}>
                                        <MdOutlineCropRotate />
                                    </button>
                              </>
                            : <>
                                <button type="button" disabled className='cor__score-piano gen__svg-alignment'><VscPiano /></button>
                                <button 
                                    type="button" 
                                    className='cor__score-show gen__svg-alignment'
                                    onClick={() => setScoreControls({...scoreControls, scoreActive: true})}
                                >
                                    <ScoreIcon eyeIcon={<CiRead />} /> 
                                </button>
                                <button type="button" disabled className='cor__score-rotate gen__svg-alignment'><MdOutlineCropRotate /></button>
                              </>
                            //     <button type="button" className='btn-piano' onClick={() => setPianoState(!pianoState)}><MdPiano /></button>
                            //     <button type="button" className='btn-score' onClick={() => {setCorScoreState(!cCorScoreState); setRotateState(false); setPianoState(false)}}> 
                            //       <ScoreIcon eyeIcon={< FaEyeSlash />} /> 
                            //     </button> 
                            //     <button type="button" className='btn-rotate' onClick={() => setRotateState(!rotateState)}><MdOutlineCropRotate /></button>
                            //     </>
                        }
                      </div>
                    : <></>
                }
                
                <CorContext.Provider value={{setVoceCor, scoreControls, setScoreControls}}>
                    <CustomAudioPlayer 
                        currentSong={{
                            index: currentSong.index,
                            nr: currentSong.nr,
                            titlu: currentSong.titlu.split(' - ')[1],
                            gama: currentSong.gama
                        }}
                        selectSong={selectCorSong}
                        songToLoad={songToLoad}
                    />
                    {
                        !scoreLndscpCondition
                        ? <div className="navigation">
                            <Navbar navType={'COR'}/>
                          </div>
                        : <></>
                    }
                    
                </CorContext.Provider>
                {
                    scoreControls.pianoActive
                    ? <Piano />
                    : <></>
                }
            </section>
        </main>
    )
}

export default Cor;