const NOTESo2 = [
    'F2' , 'Gb2', 'G2' ,'Ab2', 
    'A2' , 'Bb2', 'B2', 'C3',
    'Db3', 'D3', 'Eb3', 'E3'
];

const NOTESo3 = [
    'F3' , 'Gb3', 'G3' ,'Ab3', 
    'A3' , 'Bb3', 'B3', 'C4',
    'Db4', 'D4', 'Eb4', 'E4'
];

const NOTES = [
    'F' , 'Gb', 'G' ,'Ab', 
    'A' , 'Bb', 'B', 'C',
    'Db', 'D', 'Eb', 'E'
];

const INSTRUMENTS = [
    'acordeon',
    'chitară',
    'flaut',
    'mandolină',
    'nai',
    'pian',
    'vioară'
];

const LITERE_ALFABET = [
    'A','B','C','D','E','F','G','H',
    'I','Î','J','L','M','N','O','P',
    'R','S','Ș','T','Ț','U','V','Z'
]

const LISTA_SECTIUNI = [
    'S','S1','S2','S3','S4','S5','S6',
    'S7','S8','S9','S10','S11','S12',
    'S13','S14','S15','S16','S17'
]

export {NOTESo2, NOTESo3, NOTES, INSTRUMENTS, LITERE_ALFABET, LISTA_SECTIUNI}