/*
*** Author      : Daniel Suciu
*** Description : This component contains the main content sections 
***               available on the website: Text, Instrumente, Cor, Arhivă
*/

import React from 'react'

import { IconContext } from "react-icons";

import { GiGrandPiano, GiMusicSpell } from "react-icons/gi";
import { FaHeadphones } from "react-icons/fa";
import { MdOutlineMenuBook } from "react-icons/md";

import { useNavigate } from 'react-router-dom'

import { MenuButtonWithIcon } from '../../models/MenuButton';

const MAIN_SECTIONS = [
  new MenuButtonWithIcon('Text', 'text', <MdOutlineMenuBook />),
  new MenuButtonWithIcon('Instrumente', 'instr', <GiGrandPiano />),
  new MenuButtonWithIcon('Cor', 'cor', <GiMusicSpell />),
  new MenuButtonWithIcon('Arhivă', 'arhiva', <FaHeadphones />),
]

function MainSections() 
{  
  const navigate = useNavigate();

  return (
    <main className='main-sections'>
      <menu className='main-sections__menu'>
          <h1 className='main-sections__menu-title'>Leviții cântăreți</h1>
          {
            MAIN_SECTIONS.map((section) => {
                return(
                    <button 
                      key={section.text} 
                      type="button" 
                      className='main-sections__menu-button'
                      onClick={() => navigate(section.targetRoute)}
                    >
                        <IconContext.Provider value={{className: 'main-sections__menu-button-icon'}}> 
                            {section.icon}
                        </IconContext.Provider>
                        <span className='main-sections__menu-button-text'>
                            {section.text}
                        </span>
                    </button>
                )
            })
          }
      </menu>
    </main>
  )
}

export default MainSections
