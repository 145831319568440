class MenuButton
{
    constructor(text, targetRoute)
    {
        this.text = text;
        this.targetRoute = targetRoute;
    }
};

class MenuButtonWithIcon extends MenuButton
{
    constructor(text, targetRoute, icon)
    {
        super(text, targetRoute);
        this.icon = icon;
    }

    displayArgs()
    {
        console.dir(this)
    }
}

export { MenuButton, MenuButtonWithIcon }