const songsListSec = [

    /* * * SECTIUNI * * */

    { titlu: 'SECȚIUNI'},
    { titlu: 'S01  - Închinare'},
    { titlu: 'S02  - Laudă'},
    { titlu: 'S03  - Mulțumire'},
    { titlu: 'S04  - Mângâiere și îmbărbătare'},
    { titlu: 'S05  - Bolnavi'},
    { titlu: 'S06  - Biruință'},
    { titlu: 'S07  - Evanghelizare'},
    { titlu: 'S08  - Umblarea pe calea Domnului'},
    { titlu: 'S09  - Reîntoarcere la Domnul'},
    { titlu: 'S10 - Nașterea Domnului Isus'},
    { titlu: 'S11 - Cină'},
    { titlu: 'S12 - Duhul Sfânt'},
    { titlu: 'S13 - Așteptarea și revenirea lui Isus'},
    { titlu: 'S14 - Binecuvântare de copii'},
    { titlu: 'S15 - Nuntă'},
    { titlu: 'S16 - Anul nou, despărțiri'},
    { titlu: 'S17 - Diverse'},

    /* * * S1 * * */

    { titlu: 'S01'},
    { titlu: 'Ce mare ești o Doamne',                    nr: '1.1',       songPrevSecID: '-',          songNextSecID: '1.10'   },
    { titlu: 'Cu aripi nevăzute',                        nr: '1.10',      songPrevSecID: '1.1',        songNextSecID: '1.11'   },
    { titlu: 'Cu lanțuri nevăzute-am fost legat',        nr: '1.11',      songPrevSecID: '1.10',       songNextSecID: '1.2'    },
    { titlu: 'Din veşnicie-n veşnicie',                  nr: '1.2',       songPrevSecID: '1.11',       songNextSecID: '1.3'    },
    { titlu: 'Domn al domnilor rege-al regilor',         nr: '1.3',       songPrevSecID: '1.2',        songNextSecID: '1.4'    },
    { titlu: 'Eşti necuprins de timp şi spaţiu',         nr: '1.4',       songPrevSecID: '1.3',        songNextSecID: '1.5'    },
    { titlu: 'Mare e Domnul meu',                        nr: '1.5',       songPrevSecID: '1.4',        songNextSecID: '1.12'   },
    { titlu: 'Nu este ca tine vreo stâncă',              nr: '1.12',      songPrevSecID: '1.5',        songNextSecID: '1.6'    },
    { titlu: 'O laudă e cântarea mea',                   nr: '1.6',       songPrevSecID: '1.12',       songNextSecID: '1.13'   },
    { titlu: 'Pentru tine vreau, Isuse',                 nr: '1.13',      songPrevSecID: '1.6',        songNextSecID: '1.7'    },
    { titlu: 'Privind la bolta-albastră',                nr: '1.7',       songPrevSecID: '1.13',       songNextSecID: '1.8'    },
    { titlu: 'Te-am privit o, Doamne te-am privit',      nr: '1.8',       songPrevSecID: '1.7',        songNextSecID: '1.9'    },
    { titlu: 'Un nume-mi place-aşa de mult',             nr: '1.9',       songPrevSecID: '1.8',        songNextSecID: '2.1'    },
    
    /* * * S2 * * */  

    { titlu: 'S02'},
    { titlu: 'Aleluia în tot timpul zilei',              nr: '2.1',       songPrevSecID: '1.9',        songNextSecID: '2.2'    },
    { titlu: 'Când nici nu existam',                     nr: '2.2',       songPrevSecID: '2.1',        songNextSecID: '2.3'    },
    { titlu: 'Cântăm voioşi şi suntem fericiţi',         nr: '2.3',       songPrevSecID: '2.2',        songNextSecID: '2.4'    },
    { titlu: 'Cât va fi pământul sfânt va răsuna',       nr: '2.4',       songPrevSecID: '2.3',        songNextSecID: '2.5'    },
    { titlu: 'Cine oare e ca Dumnezeul meu',             nr: '2.5',       songPrevSecID: '2.4',        songNextSecID: '2.30'   },
    { titlu: 'Cinste şi onoare',                         nr: '2.30',      songPrevSecID: '2.5',        songNextSecID: '2.6'    },
    { titlu: 'Cu încredere Dumnezeule',                  nr: '2.6',       songPrevSecID: '2.30',       songNextSecID: '2.8'    },
    { titlu: 'Cum aş putea să tac',                      nr: '2.8',       songPrevSecID: '2.6',        songNextSecID: '2.9'    },
    { titlu: 'Cum să nu-l slăvesc pe  Domnul meu',       nr: '2.9',       songPrevSecID: '2.8',        songNextSecID: '2.7'    },
    { titlu: 'Cu Tine mă laud mereu',                    nr: '2.7',       songPrevSecID: '2.9',        songNextSecID: '2.31'   },
    { titlu: 'De n-ai fi Tu în orice floare',            nr: '2.31',      songPrevSecID: '2.7',        songNextSecID: '2.10'   },
    { titlu: 'Domnul e aici',                            nr: '2.10',      songPrevSecID: '2.31',       songNextSecID: '2.32'   },
    { titlu: 'Domnu-mi poartă grija',                    nr: '2.32',      songPrevSecID: '2.10',       songNextSecID: '2.11'   },
    { titlu: 'Gata-mi este inima să cânte',              nr: '2.11',      songPrevSecID: '2.32',       songNextSecID: '2.12'   },
    { titlu: 'Iată-mă Isuse stau naintea Ta',            nr: '2.12',      songPrevSecID: '2.11',       songNextSecID: '2.13'   },
    { titlu: 'Inima din pieptul meu',                    nr: '2.13',      songPrevSecID: '2.12',       songNextSecID: '2.14'   },
    { titlu: 'Inima mi-e gata să-ți cânte',              nr: '2.14',      songPrevSecID: '2.13',       songNextSecID: '2.33'   },
    { titlu: 'Isuse, numele Tău sfânt',                  nr: '2.33',      songPrevSecID: '2.14',       songNextSecID: '2.15'   },
    { titlu: 'În orice făptură şi-orice creatură',       nr: '2.15',      songPrevSecID: '2.33',       songNextSecID: '2.16'   },
    { titlu: 'În veci slăvit fie Domnul',                nr: '2.16',      songPrevSecID: '2.15',       songNextSecID: '2.17'   },
    { titlu: 'Meriţi toată slava meriţi toată cinstea',  nr: '2.17',      songPrevSecID: '2.16',       songNextSecID: '2.18'   },
    { titlu: 'Nu pot uita vreodată',                     nr: '2.18',      songPrevSecID: '2.17',       songNextSecID: '2.19'   },
    { titlu: 'Nu va fi o veste mai frumoasă',            nr: '2.19',      songPrevSecID: '2.18',       songNextSecID: '2.20'   },
    { titlu: 'O stâncă eu am',                           nr: '2.20',      songPrevSecID: '2.19',       songNextSecID: '2.21'   },
    { titlu: 'Să cânt să cânt Tu-mi ceri mereu',         nr: '2.21',      songPrevSecID: '2.20',       songNextSecID: '2.22'   },
    { titlu: 'Slăvesc pe Domnul pacea mea',              nr: '2.22',      songPrevSecID: '2.21',       songNextSecID: '2.23'   },
    { titlu: 'Sunt un mesager rătăcitor prin lume',      nr: '2.23',      songPrevSecID: '2.22',       songNextSecID: '2.24'   },
    { titlu: 'Tatăl nostru care eşti în cer',            nr: '2.24',      songPrevSecID: '2.23',       songNextSecID: '2.34'   },
    { titlu: 'Te înalţ pe tine Domnul meu',              nr: '2.34',      songPrevSecID: '2.24',       songNextSecID: '2.27'   },
    { titlu: 'Tu-ai fost mereu de partea mea',           nr: '2.27',      songPrevSecID: '2.34',       songNextSecID: '2.25'   },
    { titlu: 'Tu din vânturi faci solii',                nr: '2.25',      songPrevSecID: '2.27',       songNextSecID: '2.26'   },
    { titlu: 'Tu meriţi toată lauda',                    nr: '2.26',      songPrevSecID: '2.25',       songNextSecID: '2.28'   },
    { titlu: 'Voi cânta în orice vreme',                 nr: '2.28',      songPrevSecID: '2.26',       songNextSecID: '2.29'   },
    { titlu: 'Voi lăuda în orice vreme',                 nr: '2.29',      songPrevSecID: '2.28',       songNextSecID: '3.1'    },
    
    /* * * S3 * * */  

    { titlu: 'S03'},
    { titlu: 'Inima-mi cântă de bucurie',                nr: '3.1',       songPrevSecID: '-',          songNextSecID: '3.2'    },
    { titlu: 'Îndreaptă-mi spre Tine inima',             nr: '3.2',       songPrevSecID: '3.1',        songNextSecID: '3.7'    },
    { titlu: 'Îți aducem Doamne mulțumire',              nr: '3.7',       songPrevSecID: '3.2',        songNextSecID: '3.3'    },
    { titlu: 'Mulţumeşte-n miez de noapte',              nr: '3.3',       songPrevSecID: '3.7',        songNextSecID: '3.6'    },  
    { titlu: 'Ne-ai fost, Doamne, ajutor',               nr: '3.6',       songPrevSecID: '3.3',        songNextSecID: '3.4'    },
    { titlu: 'Omule ce-n lume astăzi te trudeşti',       nr: '3.4',       songPrevSecID: '3.6',        songNextSecID: '3.5'    },
    { titlu: 'Sunt dator Isuse toată viaţa mea',         nr: '3.5',       songPrevSecID: '3.4',        songNextSecID: '4.25'   },

    /* * * S4 * * */  

    { titlu: 'S04'},
    { titlu: 'Am fost odată tânăr',                      nr: '4.25',      songPrevSecID: '3.5',        songNextSecID: '4.1'    },
    { titlu: 'Ascultă poporul meu şi îţi voi vorbi',     nr: '4.1',       songPrevSecID: '4.25',       songNextSecID: '4.2'    },
    { titlu: 'Biruinţa se primeşte prin credinţă',       nr: '4.2',       songPrevSecID: '4.1',        songNextSecID: '4.26'   },
    { titlu: 'Călător ce ai pornit spre cer',            nr: '4.26',      songPrevSecID: '4.2',        songNextSecID: '4.3'    },
    { titlu: 'Când pe marea vieţii',                     nr: '4.3',       songPrevSecID: '4.26',       songNextSecID: '4.4'    },
    { titlu: 'Când privesc bolta-nstelată',              nr: '4.4',       songPrevSecID: '4.3',        songNextSecID: '4.5'    },
    { titlu: 'Când te chem în aşteptare',                nr: '4.5',       songPrevSecID: '4.4',        songNextSecID: '4.6'    },
    { titlu: 'Cântaţi cu toţi vă bucuraţi',              nr: '4.6',       songPrevSecID: '4.5',        songNextSecID: '4.7'    },
    { titlu: 'Cu lacrimi noi ne bucurăm',                nr: '4.7',       songPrevSecID: '4.6',        songNextSecID: '4.8'    },
    { titlu: 'De ce porţi tu povara',                    nr: '4.8',       songPrevSecID: '4.7',        songNextSecID: '4.9'    },
    { titlu: 'Doamne rugăciunea mea',                    nr: '4.9',       songPrevSecID: '4.8',        songNextSecID: '4.29'   },
    { titlu: 'Dumnezeu e adăpostul',                     nr: '4.29',      songPrevSecID: '4.9',        songNextSecID: '4.10'   },
    { titlu: 'Eu mă încred în Domnul meu',               nr: '4.10',      songPrevSecID: '4.29',       songNextSecID: '4.11'   },
    { titlu: 'Eu n-am să te las ca să pieri',            nr: '4.11',      songPrevSecID: '4.10',       songNextSecID: '4.27'   },
    { titlu: 'Isus ne-a salvat de la moarte',            nr: '4.27',      songPrevSecID: '4.11',       songNextSecID: '4.12'   },
    { titlu: 'În clipe de grea încercare',               nr: '4.12',      songPrevSecID: '4.27',       songNextSecID: '4.13'   },
    { titlu: 'Încrede-te Israel încrede-te mereu',       nr: '4.13',      songPrevSecID: '4.12',       songNextSecID: '4.14'   },
    { titlu: 'La umbra aripilor tale',                   nr: '4.14',      songPrevSecID: '4.13',       songNextSecID: '4.16'   },
    { titlu: 'Nu-i nimeni ca Tine Isus',                 nr: '4.16',      songPrevSecID: '4.14',       songNextSecID: '4.15'   },
    { titlu: 'Nu vă-ngrijoraţi de viaţa voastră',        nr: '4.15',      songPrevSecID: '4.16',       songNextSecID: '4.17'   },
    { titlu: 'O, ce har să ştii că Domnul te iubeşte',   nr: '4.17',      songPrevSecID: '4.15',       songNextSecID: '4.18'   },
    { titlu: 'Porumbiţă scumpă-ascunsă între stânci',    nr: '4.18',      songPrevSecID: '4.17',       songNextSecID: '4.28'   },
    { titlu: 'Să nu te temi când vezi in jur',           nr: '4.28',      songPrevSecID: '4.18',       songNextSecID: '4.19'   },
    { titlu: 'Să te-asculte Domnu-n ziua de necaz',      nr: '4.19',      songPrevSecID: '4.28',       songNextSecID: '4.20'   },
    { titlu: 'Sunt numai un vas de lut',                 nr: '4.20',      songPrevSecID: '4.19',       songNextSecID: '4.21'   },
    { titlu: 'Şi viaţa ta e ca o barcă pe ocean',        nr: '4.21',      songPrevSecID: '4.20',       songNextSecID: '4.22'   },
    { titlu: 'Te chem din adânc',                        nr: '4.22',      songPrevSecID: '4.21',       songNextSecID: '4.23'   },
    { titlu: 'Trăirea prin credință',                    nr: '4.23',      songPrevSecID: '4.22',       songNextSecID: '4.24'   },
    { titlu: 'Vocea lui se aude',                        nr: '4.24',      songPrevSecID: '4.23',       songNextSecID: '5.1'    },
   
    /* * * S5 * * */  
  
    { titlu: 'S05'},
    { titlu: 'Bun medic eşti Isuse',                     nr: '5.1',       songPrevSecID: '4.24',       songNextSecID: '5.2'    },
    { titlu: 'Când Isus şedea pe munte',                 nr: '5.2',       songPrevSecID: '5.1',        songNextSecID: '5.3'    },
    { titlu: 'De eşti trudit şi ţi-e grea povara',       nr: '5.3',       songPrevSecID: '5.2',        songNextSecID: '5.4'    },
    { titlu: 'Eu cred în vindecările divine',            nr: '5.4',       songPrevSecID: '5.3',        songNextSecID: '5.5'    },
    { titlu: 'La poarta cea frumoasă sta ologul',        nr: '5.5',       songPrevSecID: '5.4',        songNextSecID: '5.6'    },
    { titlu: 'Nu te-ndoi ci crede oricât de grea',       nr: '5.6',       songPrevSecID: '5.5',        songNextSecID: '5.7'    },
    { titlu: 'Trecea Isus prin Galileea',                nr: '5.7',       songPrevSecID: '5.6',        songNextSecID: '6.14'   },
  
    /* * * S6 * * */  
  
    { titlu: 'S06'},
    { titlu: 'Acum când încă se mai poate',              nr: '6.14',      songPrevSecID: '5.7',        songNextSecID: '6.15'   },
    { titlu: 'Astăzi Doamne eu te rog să intri',         nr: '6.15',      songPrevSecID: '6.14',       songNextSecID: '6.44'   },
    { titlu: 'Când Elisei profetul',                     nr: '6.44',      songPrevSecID: '6.15',       songNextSecID: '6.1'    },
    { titlu: 'Când eşti lângă mine',                     nr: '6.1',       songPrevSecID: '6.44',       songNextSecID: '6.16'   },
    { titlu: 'Cât de-nfricoşat e locul unde',            nr: '6.16',      songPrevSecID: '6.1',        songNextSecID: '6.43'   },
    { titlu: 'Ce bine-i să mă-ncred',                    nr: '6.43',      songPrevSecID: '6.16',       songNextSecID: '6.17'   },
    { titlu: 'Ce plăcută-i părtăşia',                    nr: '6.17',      songPrevSecID: '6.43',       songNextSecID: '6.2'    },
    { titlu: 'Cei ce se încred',                         nr: '6.2',       songPrevSecID: '6.17',       songNextSecID: '6.18'   },
    { titlu: 'Cele mai frumoase momente din viaţă',      nr: '6.18',      songPrevSecID: '6.2',        songNextSecID: '6.19'   },
    { titlu: 'Cerul şi pământul ne spune Cuvântul',      nr: '6.19',      songPrevSecID: '6.18',       songNextSecID: '6.3'    },
    { titlu: 'Chiar de s-ar clătina pământul',           nr: '6.3',       songPrevSecID: '6.19',       songNextSecID: '6.20'   },
    { titlu: 'Cine vrea să simtă azi pe Domnul',         nr: '6.20',      songPrevSecID: '6.3',        songNextSecID: '6.21'   },
    { titlu: 'Cu haina iubirii s-acopăr mereu',          nr: '6.21',      songPrevSecID: '6.20',       songNextSecID: '6.22'   },
    { titlu: 'Dac-ai ajuns în ape liniştite',            nr: '6.22',      songPrevSecID: '6.21',       songNextSecID: '6.23'   },
    { titlu: 'Dacă pleci la luptă frate',                nr: '6.23',      songPrevSecID: '6.22',       songNextSecID: '6.24'   },
    { titlu: 'De-atâtea ori te afli',                    nr: '6.24',      songPrevSecID: '6.23',       songNextSecID: '6.45'   },
    { titlu: 'Din neam în neam eşti adăpost',            nr: '6.45',      songPrevSecID: '6.24',       songNextSecID: '6.4'    },
    { titlu: 'Domnul doarme în corăbioară',              nr: '6.4',       songPrevSecID: '6.45',       songNextSecID: '6.5'    },
    { titlu: 'Domnul împărăţeşte',                       nr: '6.5',       songPrevSecID: '6.4',        songNextSecID: '6.6'    },
    { titlu: 'Dumnezeu ne este adăpost',                 nr: '6.6',       songPrevSecID: '6.5',        songNextSecID: '6.25'   },
    { titlu: 'E mult prea scumpă viaţa asta',            nr: '6.25',      songPrevSecID: '6.6',        songNextSecID: '6.7'    },
    { titlu: 'Fraţilor credinţa noastră s-o păzim',      nr: '6.7',       songPrevSecID: '6.25',       songNextSecID: '6.8'    },
    { titlu: 'În orice clipă-n viaţa mea',               nr: '6.8',       songPrevSecID: '6.7',        songNextSecID: '6.26'   },
    { titlu: 'În orice suflet e-un tainic dor',          nr: '6.26',      songPrevSecID: '6.8',        songNextSecID: '6.27'   },
    { titlu: 'În slăvitu-ţi nume mare',                  nr: '6.27',      songPrevSecID: '6.26',       songNextSecID: '6.28'   },
    { titlu: 'Într-o zi pe Ieremia',                     nr: '6.28',      songPrevSecID: '6.27',       songNextSecID: '6.29'   },
    { titlu: 'Înţelepţii lumii',                         nr: '6.29',      songPrevSecID: '6.28',       songNextSecID: '6.30'   },
    { titlu: 'Laudă astăzi pe Domnul',                   nr: '6.30',      songPrevSecID: '6.29',       songNextSecID: '6.31'   },
    { titlu: 'Mergeţi vestiţi',                          nr: '6.31',      songPrevSecID: '6.30',       songNextSecID: '6.9'    },
    { titlu: 'Mi-am pus nădejdea-n Tine',                nr: '6.9',       songPrevSecID: '6.31',       songNextSecID: '6.32'   },
    { titlu: 'Nu e nici un om în lume fără de păcat',    nr: '6.32',      songPrevSecID: '6.9',        songNextSecID: '6.33'   },
    { titlu: 'Nu sunt bogat în astă lume',               nr: '6.33',      songPrevSecID: '6.32',       songNextSecID: '6.34'   },
    { titlu: 'O, Doamne fă să vie',                      nr: '6.34',      songPrevSecID: '6.33',       songNextSecID: '6.35'   },
    { titlu: 'Păstraţi legătura frăţească',              nr: '6.35',      songPrevSecID: '6.34',       songNextSecID: '6.36'   },
    { titlu: 'Pe vremuri stă Iosua',                     nr: '6.36',      songPrevSecID: '6.35',       songNextSecID: '6.37'   },
    { titlu: 'Roagă-te fără-ncetare',                    nr: '6.37',      songPrevSecID: '6.36',       songNextSecID: '6.10'   },
    { titlu: 'Să fie furtună şi valuri şi ploi',         nr: '6.10',      songPrevSecID: '6.37',       songNextSecID: '6.38'   },
    { titlu: 'Să fie rugăciunea ta',                     nr: '6.38',      songPrevSecID: '6.10',       songNextSecID: '6.39'   },
    { titlu: 'Să nu se laude cel ce-ncinge arma',        nr: '6.39',      songPrevSecID: '6.38',       songNextSecID: '6.11'   },
    { titlu: 'Se va cânta cântarea minunată',            nr: '6.11',      songPrevSecID: '6.39',       songNextSecID: '6.12'   },
    { titlu: 'Sunt pe calea Domnului',                   nr: '6.12',      songPrevSecID: '6.11',       songNextSecID: '6.40'   },
    { titlu: 'Te-ai sfărâmat tu vasule-ncercat',         nr: '6.40',      songPrevSecID: '6.12',       songNextSecID: '6.41'   },
    { titlu: 'Tu dar copilul meu',                       nr: '6.41',      songPrevSecID: '6.40',       songNextSecID: '6.13'   },
    { titlu: 'Tu ne-ai fost tărie',                      nr: '6.13',      songPrevSecID: '6.41',       songNextSecID: '6.42'   },
    { titlu: 'Ucenicii pe Isus l-au întrebat',           nr: '6.42',      songPrevSecID: '6.13',       songNextSecID: '7.1'    },
  
    /* * * S7 * * */  
  
    { titlu: 'S07'},
    { titlu: 'Ai plătit un preţ să-mi dai viaţă',        nr: '7.1',       songPrevSecID: '6.42',       songNextSecID: '7.2'    },
    { titlu: 'Am găsit în Domnul fericirea',             nr: '7.2',       songPrevSecID: '7.1',        songNextSecID: '7.3'    },
    { titlu: 'Am umblat pe cărările lumii',              nr: '7.3',       songPrevSecID: '7.2',        songNextSecID: '7.4'    },
    { titlu: 'Amăgit am fost în lume',                   nr: '7.4',       songPrevSecID: '7.3',        songNextSecID: '7.32'   },
    { titlu: 'Anii se duc și viața trece',               nr: '7.32',      songPrevSecID: '7.4',        songNextSecID: '7.7'    },
    { titlu: 'Când vei vedea în ziua-aceea',             nr: '7.7',       songPrevSecID: '7.32',       songNextSecID: '7.5'    },
    { titlu: 'Cred în Dumnezeu ca Tată',                 nr: '7.5',       songPrevSecID: '7.7',        songNextSecID: '7.6'    },
    { titlu: 'Dac-aş fi stăpân pe lumea-ntreagă',        nr: '7.6',       songPrevSecID: '7.5',        songNextSecID: '7.14'   },
    { titlu: 'Dacă toate trec în viaţă',                 nr: '7.14',      songPrevSecID: '7.6',        songNextSecID: '7.15'   },
    { titlu: 'Dacă vei vrea să primeşti',                nr: '7.15',      songPrevSecID: '7.14',       songNextSecID: '7.30'   },
    { titlu: 'De-atâta timp ești așteptat',              nr: '7.30',      songPrevSecID: '7.15',       songNextSecID: '7.29'   },
    { titlu: 'De-atâtea ori ai auzit Cuvântul',          nr: '7.29',      songPrevSecID: '7.30',       songNextSecID: '7.11'   },
    { titlu: 'De când cu Tine, Doamne eu trăiesc',       nr: '7.11',      songPrevSecID: '7.29',       songNextSecID: '7.16'   },
    { titlu: 'Domnul bate la uşa ta',                    nr: '7.16',      songPrevSecID: '7.11',       songNextSecID: '7.17'   },
    { titlu: 'Dumnezeu ne cheamă pe toţi',               nr: '7.17',      songPrevSecID: '7.16',       songNextSecID: '7.18'   },
    { titlu: 'Este-o zi de har totul e în dar',          nr: '7.18',      songPrevSecID: '7.17',       songNextSecID: '7.33'   },
    { titlu: 'Există după moarte o veşnicie',            nr: '7.33',      songPrevSecID: '7.18',       songNextSecID: '7.19'   },
    { titlu: 'Există un timp pentru toate',              nr: '7.19',      songPrevSecID: '7.33',       songNextSecID: '7.20'   },
    { titlu: 'Fără de Hristos tu nu poţi trăi',          nr: '7.20',      songPrevSecID: '7.19',       songNextSecID: '7.21'   },
    { titlu: 'Glasul Tatălui ceresc',                    nr: '7.21',      songPrevSecID: '7.20',       songNextSecID: '7.28'   },
    { titlu: 'Iată cerul aşteaptă candidaţi',            nr: '7.28',      songPrevSecID: '7.21',       songNextSecID: '7.8'    },
    { titlu: 'Iată se vesteşte ca niciodată',            nr: '7.8',       songPrevSecID: '7.28',       songNextSecID: '7.9'    },
    { titlu: 'Isus păcatul ne-a iertat',                 nr: '7.9',       songPrevSecID: '7.8',        songNextSecID: '7.22'   },
    { titlu: 'Încă mai este loc la cruce',               nr: '7.22',      songPrevSecID: '7.9',        songNextSecID: '7.34'   },
    { titlu: 'Întoarce-te-acum, la al tău Făcător',      nr: '7.34',      songPrevSecID: '7.22',       songNextSecID: '7.10'   },
    { titlu: 'M-am hotărât să vin la Tine',              nr: '7.10',      songPrevSecID: '7.34',       songNextSecID: '7.23'   },
    { titlu: 'Numai o dată tu trăieşti',                 nr: '7.23',      songPrevSecID: '7.10',       songNextSecID: '7.35'   },
    { titlu: 'Nu te mai vreau lume',                     nr: '7.35',      songPrevSecID: '7.23',       songNextSecID: '7.24'   },
    { titlu: 'O poartă e deschisă',                      nr: '7.24',      songPrevSecID: '7.35',       songNextSecID: '7.25'   },
    { titlu: 'Poate eşti iubit, poate eşti părăsit',     nr: '7.25',      songPrevSecID: '7.24',       songNextSecID: '7.12'   },
    { titlu: 'Străbătând în lung şi-n lat',              nr: '7.12',      songPrevSecID: '7.25',       songNextSecID: '7.36'   },
    { titlu: 'Suflete eşti gata eşti tu mântuit',        nr: '7.36',      songPrevSecID: '7.12',       songNextSecID: '7.13'   },
    { titlu: 'Sunt un om nevrednic',                     nr: '7.13',      songPrevSecID: '7.36',       songNextSecID: '7.26'   },
    { titlu: 'Tu cel fără pace-n suflet',                nr: '7.26',      songPrevSecID: '7.13',       songNextSecID: '7.31'   },
    { titlu: 'Tu tot stai pe mal de prăpăstii',          nr: '7.31',      songPrevSecID: '7.26',       songNextSecID: '7.27'   },
    { titlu: 'Un cor îngeresc',                          nr: '7.27',      songPrevSecID: '7.31',       songNextSecID: '8.1'    },
  
    /* * * S8 * * */  
  
    { titlu: 'S08'},
    { titlu: 'Al cerului cântec nespus de frumos',       nr: '8.1',       songPrevSecID: '7.27',       songNextSecID: '8.3'    },
    { titlu: 'Binecuvântată-i viaţa',                    nr: '8.3',       songPrevSecID: '8.1',        songNextSecID: '8.2'    },
    { titlu: 'Bine-i lângă tine călător când sunt',      nr: '8.2',       songPrevSecID: '8.3',        songNextSecID: '8.4'    },
    { titlu: 'Calea-ngustă e greu de urmat',             nr: '8.4',       songPrevSecID: '8.2',        songNextSecID: '8.20'   },
    { titlu: 'Cât de minunat e în lumina',               nr: '8.20',      songPrevSecID: '8.4',        songNextSecID: '8.24'   },
    { titlu: 'Ce har, ce iubire în Tine găsesc',         nr: '8.24',      songPrevSecID: '8.20',       songNextSecID: '8.25'   },
    { titlu: 'Cel ce legi răni care dor',                nr: '8.25',      songPrevSecID: '8.24',       songNextSecID: '8.5'    },
    { titlu: 'Cu Isus vreau să-mi continui calea',       nr: '8.5',       songPrevSecID: '8.25',       songNextSecID: '8.6'    },
    { titlu: 'Cu Tine Doamne Isuse',                     nr: '8.6',       songPrevSecID: '8.5',        songNextSecID: '8.23'   },
    { titlu: 'Cu Tine doar cu Tine',                     nr: '8.23',      songPrevSecID: '8.6',        songNextSecID: '8.7'    },
    { titlu: 'Dacă-ţi spune cineva',                     nr: '8.7',       songPrevSecID: '8.23',       songNextSecID: '8.8'    },
    { titlu: 'Dă-ne putere Doamne azi îţi cerem',        nr: '8.8',       songPrevSecID: '8.7',        songNextSecID: '8.26'   },
    { titlu: 'Dă-ți solzii de pe ochi',                  nr: '8.26',      songPrevSecID: '8.8',        songNextSecID: '8.9'    },
    { titlu: 'De când Te cunosc Isuse',                  nr: '8.9',       songPrevSecID: '8.26',       songNextSecID: '8.10'   },
    { titlu: 'De când trăiesc o Doamne lângă Tine',      nr: '8.10',      songPrevSecID: '8.9',        songNextSecID: '8.11'   },
    { titlu: 'Dumnezeu m-a creat cu iubire',             nr: '8.11',      songPrevSecID: '8.10',       songNextSecID: '8.12'   },
    { titlu: 'Eu sunt calea-a spus Domnul Isus',         nr: '8.12',      songPrevSecID: '8.11',       songNextSecID: '8.13'   },
    { titlu: 'Iată ce sunt, iată ce am',                 nr: '8.13',      songPrevSecID: '8.12',       songNextSecID: '8.21'   },
    { titlu: 'Isuse, prieten drag',                      nr: '8.21',      songPrevSecID: '8.13',       songNextSecID: '8.14'   },
    { titlu: 'La orice pas ce-n viaţă îl fac',           nr: '8.14',      songPrevSecID: '8.21',       songNextSecID: '8.15'   },
    { titlu: 'Mă lupt să trăiesc adevărul',              nr: '8.15',      songPrevSecID: '8.14',       songNextSecID: '8.16'   },
    { titlu: 'Nu-ţi cer nimic în a mea rugă',            nr: '8.16',      songPrevSecID: '8.15',       songNextSecID: '8.27'   },
    { titlu: 'Pe drumul către patria iubirii',           nr: '8.27',      songPrevSecID: '8.16',       songNextSecID: '8.17'   },
    { titlu: 'Pe Tini te laud Isuse',                    nr: '8.17',      songPrevSecID: '8.27',       songNextSecID: '8.18'   },
    { titlu: 'Să am tot ce privirea',                    nr: '8.18',      songPrevSecID: '8.17',       songNextSecID: '8.19'   },
    { titlu: 'Să mergi pe calea-ngustă',                 nr: '8.19',      songPrevSecID: '8.18',       songNextSecID: '8.22'   },
    { titlu: 'Ți-am cunoscut iubirea',                   nr: '8.22',      songPrevSecID: '8.19',       songNextSecID: '9.1'    },
  
    /* * * S9 * * */  

    { titlu: 'S09'},
    { titlu: 'Aşa vorbeşte Cel ce-i sfânt şi mare',      nr: '9.1',       songPrevSecID: '8.22',       songNextSecID: '9.2'    },
    { titlu: 'Avem nevoie de Dumnezeu',                  nr: '9.2',       songPrevSecID: '9.1',        songNextSecID: '9.3'    },
    { titlu: 'Când mă aflu-n prezenţa Ta',               nr: '9.3',       songPrevSecID: '9.2',        songNextSecID: '9.4'    },
    { titlu: 'Câte doruri îmi frământă viaţa',           nr: '9.4',       songPrevSecID: '9.3',        songNextSecID: '9.5'    },
    { titlu: 'De ce anii mei se duc în vânt',            nr: '9.5',       songPrevSecID: '9.4',        songNextSecID: '9.6'    },
    { titlu: 'Grădină minunată a iubirii',               nr: '9.6',       songPrevSecID: '9.5',        songNextSecID: '9.7'    },
    { titlu: 'Îngenuncheat cu faţa-n palme',             nr: '9.7',       songPrevSecID: '9.6',        songNextSecID: '9.8'    },
    { titlu: 'La crucea de la Golgota',                  nr: '9.8',       songPrevSecID: '9.7',        songNextSecID: '9.9'    },
    { titlu: 'La Tine Doamne îmi înalţ sufletul',        nr: '9.9',       songPrevSecID: '9.8',        songNextSecID: '9.10'   },
    { titlu: 'Mai adu-ţi, adu-ţi aminte',                nr: '9.10',      songPrevSecID: '9.9',        songNextSecID: '9.11'   },
    { titlu: 'Pentru cine-ţi bate bate',                 nr: '9.11',      songPrevSecID: '9.10',       songNextSecID: '9.12'   },
    { titlu: 'Ridică-ţi astăzi privirea',                nr: '9.12',      songPrevSecID: '9.11',       songNextSecID: '9.13'   },
    { titlu: 'Tu mucul care fumegă nu-l stingi',         nr: '9.13',      songPrevSecID: '9.12',       songNextSecID: '9.14'   },
    { titlu: 'Zideşte în mine',                          nr: '9.14',      songPrevSecID: '9.13',       songNextSecID: '10.1'   },
  
    /* * * S10 * * */  
  
    { titlu: 'S10'},
    { titlu: 'Astăzi un cântec răsună',                  nr: '10.1',      songPrevSecID: '9.14',       songNextSecID: '10.3'   },
    { titlu: 'Demult profeţii-au profeţit',              nr: '10.3',      songPrevSecID: '10.1',       songNextSecID: '10.4'   },
    { titlu: 'Din a cerului mărire',                     nr: '10.4',      songPrevSecID: '10.3',       songNextSecID: '10.10'  },
    { titlu: 'E nașterea Domnului',                      nr: '10.10',     songPrevSecID: '10.4',       songNextSecID: '10.5'   },
    { titlu: 'În Betleem azi e o mare minune',           nr: '10.5',      songPrevSecID: '10.10',      songNextSecID: '10.2'   },
    { titlu: 'În oraşul Betleem ni s-a născut',          nr: '10.2',      songPrevSecID: '10.5',       songNextSecID: '10.6'   },
    { titlu: 'Într-o iesle într-un grajd',               nr: '10.6',      songPrevSecID: '10.2',       songNextSecID: '10.7'   },
    { titlu: 'Într-un sat ne-nsemnat din Iudeea',        nr: '10.7',      songPrevSecID: '10.6',       songNextSecID: '10.8'   },
    { titlu: 'O, ce veste îmbucurătoare',                nr: '10.8',      songPrevSecID: '10.7',       songNextSecID: '10.9'   },
    { titlu: 'S-a născut un Împărat',                    nr: '10.9',      songPrevSecID: '10.8',       songNextSecID: '10.11'  },
    { titlu: 'Vestea bună din cer',                      nr: '10.11',     songPrevSecID: '10.9',       songNextSecID: '11.1'   },
    
    /* * * S11 * * */ 

    { titlu: 'S11'},
    { titlu: 'Ai fost tu să-L vezi',                     nr: '11.1',      songPrevSecID: '10.11',      songNextSecID: '11.2'   },
    { titlu: 'Auzi ciocanele cum bat',                   nr: '11.2',      songPrevSecID: '11.1',       songNextSecID: '11.14'  },    
    { titlu: 'Bătea o aripă de vânt',                    nr: '11.14',     songPrevSecID: '11.2',       songNextSecID: '11.15'  },
    { titlu: 'Când încă nu eram',                        nr: '11.15',     songPrevSecID: '11.14',      songNextSecID: '11.3'   },
    { titlu: 'Crucea-i ascultarea',                      nr: '11.3',      songPrevSecID: '11.15',      songNextSecID: '11.4'   },
    { titlu: 'Cu fruntea-nsângerată',                    nr: '11.4',      songPrevSecID: '11.3',       songNextSecID: '11.5'   },
    { titlu: 'De-atâtea ori Te-am întristat',            nr: '11.5',      songPrevSecID: '11.4',       songNextSecID: '11.6'   },
    { titlu: 'Din slava cerului sublim',                 nr: '11.6',      songPrevSecID: '11.5',       songNextSecID: '11.7'   },
    { titlu: 'E mare judecată la Gabata',                nr: '11.7',      songPrevSecID: '11.6',       songNextSecID: '11.8'   },
    { titlu: 'Golgota, loc de durere',                   nr: '11.8',      songPrevSecID: '11.7',       songNextSecID: '11.18'  },
    { titlu: 'Împlinește-ți misiunea',                   nr: '11.18',     songPrevSecID: '11.8',       songNextSecID: '11.9'   },
    { titlu: 'O, ce valuri de-ndurare',                  nr: '11.9',      songPrevSecID: '11.18',      songNextSecID: '11.16'  },
    { titlu: 'Pe un deal pe o cruce',                    nr: '11.16',     songPrevSecID: '11.9',       songNextSecID: '11.10'  },
    { titlu: 'Pentru-a mea răscumpărare',                nr: '11.10',     songPrevSecID: '11.16',      songNextSecID: '11.11'  },
    { titlu: 'Pentru mine Dumnezeu',                     nr: '11.11',     songPrevSecID: '11.10',      songNextSecID: '11.12'  },
    { titlu: 'Prin multe ţări frumoase',                 nr: '11.12',     songPrevSecID: '11.11',      songNextSecID: '11.17'  },
    { titlu: 'Sus pe deal, cu chip ciudat',              nr: '11.17',     songPrevSecID: '11.12',      songNextSecID: '11.13'  },    
    { titlu: 'Vrei tu să scapi',                         nr: '11.13',     songPrevSecID: '11.13',      songNextSecID: '12.1'   },
  
    /* * * S12 * * */  

    { titlu: 'S12'},
    { titlu: 'Aici să fie casa',                         nr: '12.1',      songPrevSecID: '11.12',       songNextSecID: '12.2'  },
    { titlu: 'Am o dorinţă',                             nr: '12.2',      songPrevSecID: '12.1',        songNextSecID: '12.8'  },
    { titlu: 'Ca și-n ziua de Rusalii',                  nr: '12.8',      songPrevSecID: '12.2',        songNextSecID: '12.3'  },
    { titlu: 'Cine cine ne va despărţi',                 nr: '12.3',      songPrevSecID: '12.8',        songNextSecID: '12.4'  },
    { titlu: 'Doamne dă-ne-nţelepciune',                 nr: '12.4',      songPrevSecID: '12.3',        songNextSecID: '12.5'  },
    { titlu: 'E secetă în ţară',                         nr: '12.5',      songPrevSecID: '12.4',        songNextSecID: '12.6'  },
    { titlu: 'La ce sunt bune toate-n viaţă',            nr: '12.6',      songPrevSecID: '12.5',        songNextSecID: '12.9'  },
    { titlu: 'Noi cerem Duhul Sfânt să vină',            nr: '12.9',      songPrevSecID: '12.9',        songNextSecID: '12.7'  },
    { titlu: 'O putere nemaipomenită',                   nr: '12.7',      songPrevSecID: '12.7',        songNextSecID: '13.1'  },

    /* * * S13 * * */ 

    { titlu: 'S13'},
    { titlu: 'A lui Hristos venire',                     nr: '13.1',      songPrevSecID: '12.7',        songNextSecID: '13.2'  },
    { titlu: 'Avem în cer o casă',                       nr: '13.2',      songPrevSecID: '13.1',        songNextSecID: '13.17' },
    { titlu: 'Casa noastră e sus',                       nr: '13.17',     songPrevSecID: '13.2',        songNextSecID: '13.18' },
    { titlu: 'Cine sunt aceştia Doamne',                 nr: '13.18',     songPrevSecID: '13.17',       songNextSecID: '13.19' },
    { titlu: 'Cu gândul la viaţa eternă',                nr: '13.19',     songPrevSecID: '13.18',       songNextSecID: '13.20' },
    { titlu: 'Cu suflet mă grăbesc',                     nr: '13.20',     songPrevSecID: '13.19',       songNextSecID: '13.30' },
    { titlu: 'Cum stăm noi aşa nepăsători',              nr: '13.30',     songPrevSecID: '13.20',       songNextSecID: '13.31' },
    { titlu: 'Cum tremură-n mână săgeata',               nr: '13.31',     songPrevSecID: '13.30',       songNextSecID: '13.42' },
    { titlu: 'Curând Domnul slavei va reveni',           nr: '13.42',     songPrevSecID: '13.31',       songNextSecID: '13.32' },
    { titlu: 'Dacă Domnul chiar la noapte ar veni',      nr: '13.32',     songPrevSecID: '13.42',       songNextSecID: '13.3'  },
    { titlu: 'De dorul tău Isus iubit',                  nr: '13.3',      songPrevSecID: '13.32',       songNextSecID: '13.4'  },
    { titlu: 'De ţara Ta Doamne',                        nr: '13.4',      songPrevSecID: '13.3',        songNextSecID: '13.44' },
    { titlu: 'E tot mai grea în noapte așteptarea',      nr: '13.44',     songPrevSecID: '13.4',        songNextSecID: '13.5'  },
    { titlu: 'Eu sunt Alfa şi Omega',                    nr: '13.5',      songPrevSecID: '13.44',       songNextSecID: '13.33' },
    { titlu: 'Fecioarele-nţelepte',                      nr: '13.33',     songPrevSecID: '13.5',        songNextSecID: '13.6'  },
    { titlu: 'Haideţi creştini azi cu toţi',             nr: '13.6',      songPrevSecID: '13.33',       songNextSecID: '13.7'  },
    { titlu: 'În curând noi vom pleca',                  nr: '13.7',      songPrevSecID: '13.6',        songNextSecID: '13.21' },
    { titlu: 'În ziua de apoi',                          nr: '13.21',     songPrevSecID: '13.7',        songNextSecID: '13.45' },
    { titlu: 'Mai e puțin pe această cale',              nr: '13.45',     songPrevSecID: '13.21',       songNextSecID: '13.8'  },
    { titlu: 'Mai e puţin şi va veni Mesia',             nr: '13.8',      songPrevSecID: '13.45',       songNextSecID: '13.34' },
    { titlu: 'Mai este mult străjer din noapte',         nr: '13.34',     songPrevSecID: '13.8',        songNextSecID: '13.9'  },
    { titlu: 'Mai mult decât aşteaptă străjerul',        nr: '13.9',      songPrevSecID: '13.34',       songNextSecID: '13.22' },
    { titlu: 'Mai sus de stele mai sus de nori',         nr: '13.22',     songPrevSecID: '13.9',        songNextSecID: '13.24' },
    { titlu: 'Mi-e dor mi-e dor de-acasă',               nr: '13.24',     songPrevSecID: '13.22',       songNextSecID: '13.23' },
    { titlu: 'Mi-e dor mi-e dor de casa mea',            nr: '13.23',     songPrevSecID: '13.24',       songNextSecID: '13.46' },
    { titlu: 'Mii de ani ce-au trecut',                  nr: '13.46',     songPrevSecID: '13.23',       songNextSecID: '13.35' },
    { titlu: 'Ne cheamă Dumnezeu pe toţi',               nr: '13.35',     songPrevSecID: '13.46',       songNextSecID: '13.10' },
    { titlu: 'Nu aceasta e menirea noastră',             nr: '13.10',     songPrevSecID: '13.35',       songNextSecID: '13.11' },
    { titlu: 'Nu mai este mult',                         nr: '13.11',     songPrevSecID: '13.10',       songNextSecID: '13.36' },
    { titlu: 'O ascultaţi o ascultaţi',                  nr: '13.36',     songPrevSecID: '13.11',       songNextSecID: '13.37' },
    { titlu: 'Omu-n lume-i ca vaporul',                  nr: '13.37',     songPrevSecID: '13.36',       songNextSecID: '13.12' },
    { titlu: 'Oricât de lungă-i noaptea',                nr: '13.12',     songPrevSecID: '13.37',       songNextSecID: '13.13' },
    { titlu: 'Pe-acest pământ pe-această cale',          nr: '13.13',     songPrevSecID: '13.12',       songNextSecID: '13.38' },
    { titlu: 'Pe pământ avem o luptă',                   nr: '13.38',     songPrevSecID: '13.13',       songNextSecID: '13.25' },
    { titlu: 'Pribegi ca Moise în pustie',               nr: '13.25',     songPrevSecID: '13.38',       songNextSecID: '13.14' },
    { titlu: 'Prin arşiţa vremii',                       nr: '13.14',     songPrevSecID: '13.25',       songNextSecID: '13.15' },
    { titlu: 'Privim prin credinţă în zare',             nr: '13.15',     songPrevSecID: '13.14',       songNextSecID: '13.39' },
    { titlu: 'Scoală-te popor şi luptă',                 nr: '13.39',     songPrevSecID: '13.15',       songNextSecID: '13.43' },
    { titlu: 'Se-aude sus pe cer',                       nr: '13.43',     songPrevSecID: '13.39',       songNextSecID: '13.40' },
    { titlu: 'Se duce viaţa ca un vis',                  nr: '13.40',     songPrevSecID: '13.43',       songNextSecID: '13.41' },
    { titlu: 'Simţim încet cum harul',                   nr: '13.41',     songPrevSecID: '13.40',       songNextSecID: '13.27' },
    { titlu: 'Suntem trecători suntem călători',         nr: '13.27',     songPrevSecID: '13.41',       songNextSecID: '13.26' },
    { titlu: 'Sunt un pribeag fără de ţară',             nr: '13.26',     songPrevSecID: '13.27',       songNextSecID: '13.16' },
    { titlu: 'Sunt veacuri multe de atunci',             nr: '13.16',     songPrevSecID: '13.26',       songNextSecID: '13.47' },
    { titlu: 'Trâmbița curând răsună',                   nr: '13.47',     songPrevSecID: '13.16',       songNextSecID: '13.28' },
    { titlu: 'Ţara minunată ţara cea de sus',            nr: '13.28',     songPrevSecID: '13.47',       songNextSecID: '13.48' },
    { titlu: 'Universul e în așteptare',                 nr: '13.48',     songPrevSecID: '13.28',       songNextSecID: '13.29' },
    { titlu: 'Vine o zi când vom pleca',                 nr: '13.29',     songPrevSecID: '13.48',       songNextSecID: '14.1'  },
  
    /* * * S14 * * */ 

    { titlu: 'S14'},
    { titlu: 'Azi Isus cu mulţumire',                    nr: '14.1',      songPrevSecID: '13.29',       songNextSecID: '14.2'  },
    { titlu: 'Copiii să vină la El',                     nr: '14.2',      songPrevSecID: '14.1',        songNextSecID: '14.3'  },
    { titlu: 'Din slava strălucirilor albastre',         nr: '14.3',      songPrevSecID: '14.2',        songNextSecID: '14.4'  },
    { titlu: 'Din ţesuturi nevăzute',                    nr: '14.4',      songPrevSecID: '14.3',        songNextSecID: '14.6'  },
    { titlu: 'Lăsaţi copilaşii să vină la mine',         nr: '14.6',      songPrevSecID: '14.4',        songNextSecID: '14.5'  },
    { titlu: 'Nu-i uşor să fii părinte',                 nr: '14.5',      songPrevSecID: '14.6',        songNextSecID: '15.1'  },
  
    /* * * S15 * * */  

    { titlu: 'S15'},
    { titlu: 'Binecuvântată-i nunta',                    nr: '15.1',      songPrevSecID: '14.5',        songNextSecID: '15.2'  },
    { titlu: 'Dragoste dragoste',                        nr: '15.2',      songPrevSecID: '15.1',        songNextSecID: '15.3'  },
    { titlu: 'Iată ziua ce-aţi cerut-o',                 nr: '15.3',      songPrevSecID: '15.2',        songNextSecID: '15.4'  },
    { titlu: 'Isus iubit când vei veni',                 nr: '15.4',      songPrevSecID: '15.3',        songNextSecID: '15.5'  },
    { titlu: 'Isuse drag azi două inimi',                nr: '15.5',      songPrevSecID: '15.4',        songNextSecID: '15.6'  },
    { titlu: 'Isuse dragostea-ţi din cer',               nr: '15.6',      songPrevSecID: '15.5',        songNextSecID: '15.7'  },
    { titlu: 'Înaintea ta venim scump salvator',         nr: '15.7',      songPrevSecID: '15.6',        songNextSecID: '15.8'  },
    { titlu: 'Mai presus de orice',                      nr: '15.8',      songPrevSecID: '15.7',        songNextSecID: '15.9'  },
    { titlu: 'Un chip de lut Tu ai făcut',               nr: '15.9',      songPrevSecID: '15.8',        songNextSecID: '15.10' },
    { titlu: 'Vino isus la nuntă',                       nr: '15.10',     songPrevSecID: '15.9',        songNextSecID: '16.1'  },
  
    /* * * S16 * * */
    
    { titlu: 'S16'},  
    { titlu: 'Acum e timpul pe sfârşit',                 nr: '16.1',      songPrevSecID: '15.10',       songNextSecID: '16.3'  },
    { titlu: 'Anii trec ca norii',                       nr: '16.3',      songPrevSecID: '16.1',        songNextSecID: '16.4'  },
    { titlu: 'Anii trec viaţa se duce',                  nr: '16.4',      songPrevSecID: '16.3',        songNextSecID: '16.2'  },
    { titlu: 'Anul nou mult dorit',                      nr: '16.2',      songPrevSecID: '16.4',        songNextSecID: '16.5'  },
    { titlu: 'Când eu privesc în urma mea',              nr: '16.5',      songPrevSecID: '16.2',        songNextSecID: '16.12' },
    { titlu: 'Chiar dacă-aici ne despărțim',             nr: '16.12',     songPrevSecID: '16.5',        songNextSecID: '16.6'  },
    { titlu: 'În curând s-a termina',                    nr: '16.6',      songPrevSecID: '16.9',        songNextSecID: '16.10' },
    { titlu: 'Mergem pe calea vieţii',                   nr: '16.10',     songPrevSecID: '16.6',        songNextSecID: '16.7'  },
    { titlu: 'O Doamne bun ziua de mâine',               nr: '16.7',      songPrevSecID: '16.10',       songNextSecID: '16.8'  },
    { titlu: 'Pentru noi e taină mare',                  nr: '16.8',      songPrevSecID: '16.7',        songNextSecID: '16.11' },
    { titlu: 'Vreau lângă Dumnezeu',                     nr: '16.11',     songPrevSecID: '16.8',        songNextSecID: '17.2'  },
  
    /* * * S17 * * */

    { titlu: 'S17'},
    { titlu: 'Acela cu ochii ca para',                   nr: '17.2',      songPrevSecID: '16.11',       songNextSecID: '17.45' },
    { titlu: 'Am cunoscut cărarea pocăinţei',            nr: '17.45',     songPrevSecID: '17.2',        songNextSecID: '17.39' },
    { titlu: 'Aprinde iarăşi focul sfânt',               nr: '17.39',     songPrevSecID: '17.45',       songNextSecID: '17.59' },
    { titlu: 'Avem în cer un Tată',                      nr: '17.59',     songPrevSecID: '17.39',       songNextSecID: '17.3'  },
    { titlu: 'Binecuvântaţi pe Domnul',                  nr: '17.3',      songPrevSecID: '17.59',       songNextSecID: '17.49' },
    { titlu: 'Ca femeia cananeancă',                     nr: '17.49',     songPrevSecID: '17.3',        songNextSecID: '17.1'  },
    { titlu: 'Călătorim prin pustia aceasta',            nr: '17.1',      songPrevSecID: '17.49',       songNextSecID: '17.4'  },
    { titlu: 'Când eram doar un plod',                   nr: '17.4',      songPrevSecID: '17.1',        songNextSecID: '17.61' },
    { titlu: 'Când omenirea se frământă',                nr: '17.61',     songPrevSecID: '17.4',        songNextSecID: '17.42' },
    { titlu: 'Când privesc liniștea serii',              nr: '17.42',     songPrevSecID: '17.61',       songNextSecID: '17.55' },
    { titlu: 'Când știu că toate-aici',                  nr: '17.55',     songPrevSecID: '17.42',       songNextSecID: '17.5'  },
    { titlu: 'Când Te-am chemat Tu ai venit',            nr: '17.5',      songPrevSecID: '17.55',       songNextSecID: '17.62' },
    { titlu: 'Când trâmbiţa Domnului',                   nr: '17.62',     songPrevSecID: '17.5',        songNextSecID: '17.27' },
    { titlu: 'Cântă și laudă-l',                         nr: '17.27',     songPrevSecID: '17.62',       songNextSecID: '17.63' },
    { titlu: 'Cât încă mai curge uleiul în vas',         nr: '17.63',     songPrevSecID: '17.27',       songNextSecID: '17.6'  },
    { titlu: 'Ce bine-i când în piept',                  nr: '17.6',      songPrevSecID: '17.63',       songNextSecID: '17.25' },
    { titlu: 'Ce bun ai fost o Domnul meu cu mine',      nr: '17.25',     songPrevSecID: '17.6',        songNextSecID: '17.36' },
    { titlu: 'Ce mare har să poți vedea',                nr: '17.36',     songPrevSecID: '17.25',       songNextSecID: '17.60' },
    { titlu: 'Ce mult te doresc, o, Isuse',              nr: '17.60',     songPrevSecID: '17.36',       songNextSecID: '17.20' },
    { titlu: 'Cu bucurie eu cânt Domnului',              nr: '17.20',     songPrevSecID: '17.60',       songNextSecID: '17.7'  },
    { titlu: 'Dacă-aş spune că iubesc',                  nr: '17.7',      songPrevSecID: '17.20',       songNextSecID: '17.8'  },
    { titlu: 'De ce mai plângi',                         nr: '17.8',      songPrevSecID: '17.7',        songNextSecID: '17.74' },
    { titlu: 'De mult timp rătăceam în păcat',           nr: '17.74',     songPrevSecID: '17.8',        songNextSecID: '17.77' },
    { titlu: 'Din stânci țâșnesc',                       nr: '17.77',     songPrevSecID: '17.74',       songNextSecID: '17.9'  },
    { titlu: 'Din strălucire jos ai venit',              nr: '17.9',      songPrevSecID: '17.77',       songNextSecID: '17.57' },
    { titlu: 'Din valea plângerii cu dor',               nr: '17.57',     songPrevSecID: '17.9',        songNextSecID: '17.10' },
    { titlu: 'Domnul e al meu păstor',                   nr: '17.10',     songPrevSecID: '17.57',       songNextSecID: '17.51' },
    { titlu: 'După două mii de ani',                     nr: '17.51',     songPrevSecID: '17.10',       songNextSecID: '17.64' },
    { titlu: 'E-o luptă, viața e-o luptă',               nr: '17.64',     songPrevSecID: '17.51',       songNextSecID: '17.65' },
    { titlu: 'Este-n cer o țară',                        nr: '17.65',     songPrevSecID: '17.64',       songNextSecID: '17.75' },
    { titlu: 'Eu sunt din acei care',                    nr: '17.75',     songPrevSecID: '17.65',       songNextSecID: '17.34' },
    { titlu: 'Frați iubiți răsună iarăși',               nr: '17.34',     songPrevSecID: '17.75',       songNextSecID: '17.29' },
    { titlu: 'Gândește-te-ntruna la cele de sus',        nr: '17.29',     songPrevSecID: '17.34',       songNextSecID: '17.32' },
    { titlu: 'Ierusalim cetate-mpodobită',               nr: '17.32',     songPrevSecID: '17.29',       songNextSecID: '17.17' },
    { titlu: 'Isus e prietenul cel bun',                 nr: '17.17',     songPrevSecID: '17.32',       songNextSecID: '17.50' },
    { titlu: 'Isuse scump, iubire nepătrunsă',           nr: '17.50',     songPrevSecID: '17.17',       songNextSecID: '17.66' },
    { titlu: 'În valea cu umbrele morții',               nr: '17.66',     songPrevSecID: '17.50',       songNextSecID: '17.37' },
    { titlu: 'În zare spre noi',                         nr: '17.37',     songPrevSecID: '17.66',       songNextSecID: '17.43' },
    { titlu: 'Încă mai răsună glasul Domnului',          nr: '17.43',     songPrevSecID: '17.37',       songNextSecID: '17.33' },
    { titlu: 'Judecata va începe',                       nr: '17.33',     songPrevSecID: '17.43',       songNextSecID: '17.54' },
    { titlu: 'M-ai binecuvântat o. Doamne',              nr: '17.54',     songPrevSecID: '17.33',       songNextSecID: '17.56' },
    { titlu: 'Mai e puțin din noapte',                   nr: '17.56',     songPrevSecID: '17.54',       songNextSecID: '17.11' },
    { titlu: 'Mai este mult Isuse drag',                 nr: '17.11',     songPrevSecID: '17.56',       songNextSecID: '17.12' },
    { titlu: 'Mai mult acum ca niciodată',               nr: '17.12',     songPrevSecID: '17.11',       songNextSecID: '17.67' },
    { titlu: 'Mai mult decât aşteaptă dimineaţa',        nr: '17.67',     songPrevSecID: '17.12',       songNextSecID: '17.40' },
    { titlu: 'Mai scoate Doamne oameni ca Ilie',         nr: '17.40',     songPrevSecID: '17.67',       songNextSecID: '17.68' },
    { titlu: 'Mă gândesc ades la Tine',                  nr: '17.68',     songPrevSecID: '17.40',       songNextSecID: '17.69' },
    { titlu: 'Mulţumim Domnului pentru ziua',            nr: '17.69',     songPrevSecID: '17.68',       songNextSecID: '17.46' },
    { titlu: 'N-am să pot eu, Doamne',                   nr: '17.46',     songPrevSecID: '17.69',       songNextSecID: '17.30' },
    { titlu: 'Navigăm pe oceanele lumii',                nr: '17.30',     songPrevSecID: '17.46',       songNextSecID: '17.44' },
    { titlu: 'Ne-ai dat iertarea',                       nr: '17.44',     songPrevSecID: '17.30',       songNextSecID: '17.70' },
    { titlu: 'Nimic nu mă va despărţi',                  nr: '17.70',     songPrevSecID: '17.44',       songNextSecID: '17.48' },
    { titlu: 'Nu se poate să nu fie',                    nr: '17.48',     songPrevSecID: '17.70',       songNextSecID: '17.52' },
    { titlu: 'Nu ştiam că-n juru-mi sunt izvoare',       nr: '17.52',     songPrevSecID: '17.48',       songNextSecID: '17.26' },
    { titlu: 'O ce frumoasă fi-va cântarea',             nr: '17.26',     songPrevSecID: '17.52',       songNextSecID: '17.71' },
    { titlu: 'O, ce minunată este viaţa',                nr: '17.71',     songPrevSecID: '17.26',       songNextSecID: '17.53' },
    { titlu: 'Ochii mei te vor vedea',                   nr: '17.53',     songPrevSecID: '17.71',       songNextSecID: '17.13' },
    { titlu: 'Până-aici Dumnezeu ne-a ajutat',           nr: '17.13',     songPrevSecID: '17.53',       songNextSecID: '17.24' },
    { titlu: 'Pe calea spre cer',                        nr: '17.24',     songPrevSecID: '17.13',       songNextSecID: '17.14' },
    { titlu: 'Pe marea vieţii vâslesc zi de zi',         nr: '17.14',     songPrevSecID: '17.24',       songNextSecID: '17.21' },
    { titlu: 'Pe mare-n corabie',                        nr: '17.21',     songPrevSecID: '17.14',       songNextSecID: '17.47' },
    { titlu: 'Pe șesul arid, nisipos',                   nr: '17.47',     songPrevSecID: '17.21',       songNextSecID: '17.80' },
    { titlu: 'Să nu lași după mine',                     nr: '17.80',     songPrevSecID: '17.47',       songNextSecID: '17.22' },
    { titlu: 'Se-aud pașii lui Isus',                    nr: '17.22',     songPrevSecID: '17.80',       songNextSecID: '17.41' },
    { titlu: 'Secerişul este foarte-aproape',            nr: '17.41',     songPrevSecID: '17.22',       songNextSecID: '17.23' },
    { titlu: 'Se-mplinesc ale Tale cuvinte',             nr: '17.23',     songPrevSecID: '17.41',       songNextSecID: '17.58' },
    { titlu: 'Smeriți venim, Doamne, la Tine',           nr: '17.58',     songPrevSecID: '17.23',       songNextSecID: '17.16' },
    { titlu: 'Stăm ridicaţi în faţa Ta',                 nr: '17.16',     songPrevSecID: '17.58',       songNextSecID: '17.72' },
    { titlu: 'Tineret, la Domnul vino azi ',             nr: '17.72',     songPrevSecID: '17.16',       songNextSecID: '17.78' },
    { titlu: 'Toată încrederea-n Tine-mi pun',           nr: '17.78',     songPrevSecID: '17.72',       songNextSecID: '17.35' },
    { titlu: 'Tot ce am aici pe-acest pământ',           nr: '17.35',     songPrevSecID: '17.78',       songNextSecID: '17.31' },
    { titlu: 'Trăim al vremilor sfârșit',                nr: '17.31',     songPrevSecID: '17.35',       songNextSecID: '17.79' },
    { titlu: 'Tu ai fost locul de-adăpost',              nr: '17.79',     songPrevSecID: '17.31',       songNextSecID: '17.15' },
    { titlu: 'Tu eşti iubirea din etern',                nr: '17.15',     songPrevSecID: '17.79',       songNextSecID: '17.28' },
    { titlu: 'Tu faci minuni',                           nr: '17.28',     songPrevSecID: '17.15',       songNextSecID: '17.73' },
    { titlu: 'Tu știi cât te-am iubit',                  nr: '17.73',     songPrevSecID: '17.28',       songNextSecID: '17.18' },
    { titlu: 'Vine ziua nu-i departe',                   nr: '17.18',     songPrevSecID: '17.73',       songNextSecID: '18.19' },
    { titlu: 'Vorbim azi mult de-un glorios trecut',     nr: '17.19',     songPrevSecID: '17.18',       songNextSecID: '17.76' },
    { titlu: 'Vremurile-s în schimbare',                 nr: '17.76',     songPrevSecID: '18.19',       songNextSecID: '17.38' },
    { titlu: 'Zidurile nalte de la Ierihon',             nr: '17.38',     songPrevSecID: '17.76',       songNextSecID: '1.1'   },

]

    const songsListSecNumber = songsListSec.map(nr => nr.nr)

    const songsListSecPrevID = songsListSec.map(songPrevID => songPrevID.songPrevSecID)

    const songsListSecNextID = songsListSec.map(songNextID => songNextID.songNextSecID)

    export { songsListSec, songsListSecNumber, songsListSecPrevID, songsListSecNextID }