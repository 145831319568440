

import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import ReactIframe from 'react-iframe'
import axios from "axios";
import { useOrientation } from "@uidotdev/usehooks";

import { FaAngleRight, FaAngleLeft } from "react-icons/fa6";
import { TfiYoutube } from "react-icons/tfi";
import { ImYoutube2 } from "react-icons/im";

import Navbar from "../../components/Navbar/Navbar";

import { songsListAlfNextID, songsListAlfPrevID } from "../../Data/list_of_songs_alf";
import { GiConsoleController } from "react-icons/gi";

const MIN_FONT_SIZE = 18;
const MAX_FONT_SIZE = 26;
const DEFAULT_FONT_SIZE = 20; 

function Lyrics()
{
    const { songNbr } = useParams();
    const [ lyrics, setLyrics ] = useState();
    const [YtubeState, setYtubeState] = useState(false)
    const songState = useLocation().state;
    const navigate = useNavigate();
    const lyricsRef = useRef();
    const screenOrientation = useOrientation();
    const [navState, setNavState] = useState(true)

    const navLndscpStyle = {
        transition: "all 0.5s ease-in-out",
        opacity: navState ? 1 : 0,
        marginBottom: navState ? '0px' : '-53px',
    }

    let nrCantare = songNbr;
    let lyrics_header = nrCantare;
    let lyrics_text = lyrics && lyrics.text;
    let lyrics_ytubeLink = lyrics && lyrics.linkYT;
    let arrCantari = [];
    let arrVersuri = [];

    if(lyrics && lyrics.gama != '')
    {
        lyrics_header = `${nrCantare} - ${lyrics && lyrics.gama}`;
    }

    function changeFontSize(action)
    {
        let lyrics_element = lyricsRef.current
        let font_size = parseInt(window.getComputedStyle(lyrics_element).fontSize)
        
        switch(action)
        {
            case 'increase':
                if(font_size <= MAX_FONT_SIZE)
                {
                    font_size += 2;
                    lyrics_element.style.fontSize = font_size + 'px';
                }
            break;

            case 'decrease':
                if(font_size > MIN_FONT_SIZE)
                {
                    font_size -= 2;
                    lyrics_element.style.fontSize = font_size + 'px';
                }
            break;

            default:
                break;
        }   
    }

    function handleNavOnOrientChg(){
        setNavState(!navState)
    }

    useEffect(() => {
        if(screenOrientation.type == 'landscape-primary' || screenOrientation.type == 'landscape-secondary'){
            lyricsRef.current.classList.add('gen__centered-text')
            lyricsRef.current.style.fontSize = '36px'
            setNavState(false)
        }else{
            lyricsRef.current.classList.remove('gen__centered-text')
            lyricsRef.current.style.fontSize = '20px'
            setNavState(true)
        }
    }, [screenOrientation.type])

    // useEffect(() => {
    //     if(screenOrientation.type == 'landscape-primary'){
    //         //setNavState(!navState)
    //     }else{
    //         setNavState(true)
    //         navStyle = {
    //             opacity: 1,
    //         };
    //     }
    // },[screenOrientation.type])

    // function goToSong(action)
    // {
    //     let index = null;
    //     switch(action)
    //     {
    //         case 'next':
    //             let nextSong = songsListAlfNextID[songState.index + 1] 
    //             if(nextSong){
    //                 index = songState.index + 1
    //             }
    //             else{
    //                 nextSong = songsListAlfNextID[songState.index + 2];
    //                 index = songState.index + 2;
    //             }
    //             nrCantare = nextSong;
    //             if((songState.index < songsListAlfNextID.length - 2)) 
    //             {
    //                 lyricsRef.current.style.fontSize = DEFAULT_FONT_SIZE + 'px'
    //                 navigate(`../text/lyrics/${nrCantare}`, {
    //                     state: {
    //                         ...songState,
    //                         index: index
    //                     }
    //                 })
    //             }
    //         break;

    //         case 'previous':
    //             let prevSong = songsListAlfPrevID[songState.index - 1] 
    //             if(prevSong){
    //                 index = songState.index - 1
    //             }
    //             else{
    //                 prevSong = songsListAlfPrevID[songState.index - 2];
    //                 index = songState.index - 2;
    //             }
    //             nrCantare = prevSong;
    //             if(songState.index > 0 && nrCantare != undefined) 
    //             {
    //                 lyricsRef.current.style.fontSize = DEFAULT_FONT_SIZE + 'px'
    //                 navigate(`../text/lyrics/${nrCantare}`, {
    //                     state: {
    //                         ...songState,
    //                         index: index
    //                     }
    //                 })
    //             }
    //         break;

    //         default:
    //             break;
    //     }
    // }

    useEffect(() =>
    {
      axios.get("../../data/CCTS_songs_text.txt")
      .then((response) => {
          arrCantari = response.data.split('#');
  
          for(let songIndexInFile = 0; songIndexInFile <= arrCantari.length-1; songIndexInFile++)
          {
            arrVersuri = arrCantari[songIndexInFile].split('\n');
  
            if(arrVersuri[0] === nrCantare)
            {
              break;
            }
          }
  
          setLyrics({
            gama: arrVersuri[1],
            linkYT: arrVersuri[2],
            text: arrVersuri.slice(3,arrVersuri.length).join('\n')
          })
          
      })
      .catch(error  => {
          console.log(error)
      })
    }, [nrCantare])

    return(
        <main className="lyrics">
            <section className="lyrics__wrapper">
                <h2 className="lyrics__header">
                    {lyrics_header}
                    <hr />
                </h2>
                {/* <div className="lyrics__controls">
                    <button 
                        type="button"
                        className="gen__svg-alignment"
                        onClick={() => goToSong('previous')}
                    >
                        <FaAngleLeft />
                    </button>
                    <button 
                        type="button"
                        className="gen__svg-alignment"
                        onClick={() => goToSong('next')}
                    >
                        <FaAngleRight />
                    </button>
                </div> */}
                <pre className="lyrics__text" ref={lyricsRef} onClick={handleNavOnOrientChg}>
                    {lyrics_text}
                </pre>
                <div className="lyrics__yTubeWrapper">
                {
                    YtubeState
                    ?   <ReactIframe 
                            url={"https://www.youtube.com/embed/" + lyrics_ytubeLink }
                            allow="fullscreen"
                            className="lyrics__youtube-player"
                            height="200"
                        />
                    :   <></>
                }
                </div>
                <button 
                    className="lyrics__show-ytube-btn gen__svg-alignment"
                    onClick={() => setYtubeState(!YtubeState)}
                >
                    <ImYoutube2 />
                </button>
               
            </section>
            <div className={'navigation'} style={navLndscpStyle}>
                <Navbar navType={'LYRICS'} changeFontSize={changeFontSize}/>
            </div> 
        </main>
    )
}

export default Lyrics;