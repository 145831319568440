const songsListAlf = [

    /* * * A - 29 * * */
    { titlu: 'A'},
    { 
      titlu: 'Acela cu ochii ca para',               
      nr: '17.2',  
      gama: 'Do M',  
      linkYT: '8a4-LnhSh2A',
      text: 
        `1. Ce mare eşti o Doamne ce mare eşti şi sfânt
        Spun stelele, natura şi vuietul de vânt
        Şi trăsnetul ce culcă stejarii la pământ
        Şi-al păsărilor cântec, mare eşti şi sfânt.

        Mare eşti mare-i slava ta
        Ai fost, eşti şi vei fi în veci aşa
        Cât de mare eşti Doamne, cât de sfânt
        Cât de mare eşti Doamne eu Îţi cânt
        Te voi lăuda mereu c-ai salvat sufletul meu
        /: Cât de mare eşti Doamne cât de sfânt :/
        
        2. O, de-ar cunoaşte, Doamne, toţi oamenii ce sfânt
        Puternic e-al Tău nume şi sfântul Tău cuvânt
        De-ar auzi chemarea şi glasul Tău cel sfânt
        Te-ar preamări de-a pururi căci mare eşti şi sfânt.
        `,
      songPrevAlfID: undefined,     
      songNextAlfID: '6.14'   
    },
    {   
      titlu: 'Acum când încă se mai poate',          
      nr: '6.14',    
      songPrevAlfID: '17.2',      
      songNextAlfID: '16.1'   
    },
    { 
      titlu: 'Acum e timpul pe sfârșit',             
      nr: '16.1', 
      gama: 'Mi M',
      linkYT: 'JWPLg7MkI9k',   
      text: 
        `1. Acum e timpul pe sfârşit 
        Nu sta suflet nepregătit
        Căci trec aşa de iute-al zilelor şirag
        Dar totuşi noi îţi mulţumim 
        Deşi suntem printre străini
        Şi te rugăm Isus iubit la noi să vii.
        
        /: Și iubirea ta nemărginită să ne-o dai
        Şi credinţa pacea care duce înspre rai 
        Fă să ne iubim în viaţa-aceasta pe pământ
        Isus iubit pân la mormânt :/
        
        2. Acum vrem viaţa să i-o dăm 
        Și din iubire să lucrăm
        În via lui Isus al nost' Mântuitor
        Căci va veni o clipă când 
        Vom sta în faţa Lui cântând
        Şi vom primi răsplata toţi din mâna Lui.
        
        3. O Grădinar Ceresc şi Sfânt 
        Mai lasă pomul pe pământ
        Căci poate anu-acesta va aduce rod
        Îl voi săpa şi-l voi uda 
        Și-un an întreg m-oi ocupa 
        Iar dacă nu la anul Eu îl voi tăia.
        
        4. În timpu-acesta ne-ncetat 
        Fă să rodim rod mai bogat
        Ca şi sămânţa din pământu-al patrulea
        Să nu dăm nici un pas "napoi 
        Să n-avem ură între noi
        Şi la necaz să zicem Doamne fii cu noi.
        `,
      songPrevAlfID: '6.14',      
      songNextAlfID: '12.1'   },
    { titlu: 'Aici să fie casa',                     nr: '12.1',    songPrevAlfID: '16.1',      songNextAlfID: '11.1'   },
    { titlu: 'Ai fost tu să-L vezi',                 nr: '11.1',    songPrevAlfID: '12.1',      songNextAlfID: '7.1'    },
    { titlu: 'Ai plătit un preț',                    nr: '7.1',     songPrevAlfID: '11.1',      songNextAlfID: '8.1'    },
    { titlu: 'Al cerului cântec',                    nr: '8.1',     songPrevAlfID: '7.1',       songNextAlfID: '2.1'    },
    { titlu: 'Aleluia în tot timpul zilei',          nr: '2.1',     songPrevAlfID: '8.1',       songNextAlfID: '13.1'   },
    { titlu: 'A lui Hristos venire',                 nr: '13.1',    songPrevAlfID: '2.1',       songNextAlfID: '7.4'    },
    { titlu: 'Amăgit am fost în lume',               nr: '7.4',     songPrevAlfID: '13.1',      songNextAlfID: '17.45'  },
    { titlu: 'Am cunoscut cărarea pocăinței',        nr: '17.45',   songPrevAlfID: '7.4',       songNextAlfID: '4.25'   },
    { titlu: 'Am fost odată tânăr',                  nr: '4.25',    songPrevAlfID: '17.45',     songNextAlfID: '7.2'    },
    { titlu: 'Am găsit în Domnul fericirea',         nr: '7.2',     songPrevAlfID: '4.25',      songNextAlfID: '12.2'   },
    { titlu: 'Am o dorință și te rog fierbinte',     nr: '12.2',    songPrevAlfID: '7.2',       songNextAlfID: '7.3'    },
    { titlu: 'Am umblat pe cărările lumii',          nr: '7.3',     songPrevAlfID: '12.2',      songNextAlfID: '16.3'   },
    { titlu: 'Anii trec ca norii',                   nr: '16.3',    songPrevAlfID: '7.3',       songNextAlfID: '16.4'   },
    { titlu: 'Anii trec viața se duce',              nr: '16.4',    songPrevAlfID: '16.3',      songNextAlfID: '7.32'   },
    { titlu: 'Anii se duc și viața trece',           nr: '7.32',    songPrevAlfID: '16.4',      songNextAlfID: '16.2'   },
    { titlu: 'Anul nou mult dorit',                  nr: '16.2',    songPrevAlfID: '7.32',      songNextAlfID: '17.39'  },
    { titlu: 'Aprinde iarăși focul sfânt',           nr: '17.39',   songPrevAlfID: '16.2',      songNextAlfID: '4.1'    },
    { titlu: 'Ascultă poporul și îți voi vorbi',     nr: '4.1',     songPrevAlfID: '17.39',     songNextAlfID: '6.15'   },
    { titlu: 'Astăzi Doamne eu te rog să intri',     nr: '6.15',    songPrevAlfID: '4.1',       songNextAlfID: '10.1'   },
    { titlu: 'Astăzi un cântec răsună',              nr: '10.1',    songPrevAlfID: '6.15',      songNextAlfID: '9.1'    },
    { titlu: 'Așa vorbește Cel ce-i sfânt și mare',  nr: '9.1',     songPrevAlfID: '10.1',      songNextAlfID: '11.2'   },
    { titlu: 'Auzi ciocanele cum bat',               nr: '11.2',    songPrevAlfID: '9.1',       songNextAlfID: '13.2'   },
    { titlu: 'Avem în cer o casă',                   nr: '13.2',    songPrevAlfID: '11.2',      songNextAlfID: '17.59'  },
    { titlu: 'Avem în cer un Tată',                  nr: '17.59',   songPrevAlfID: '13.2',      songNextAlfID: '9.2'    },
    { titlu: 'Avem nevoie de Dumnezeu',              nr: '9.2',     songPrevAlfID: '17.59',     songNextAlfID: '14.1'   },
    { titlu: 'Azi Isus cu mulțumire',                nr: '14.1',    songPrevAlfID: '9.2',       songNextAlfID: '11.14'  },
    
    /* * * B -7 * * */  
  
    { titlu: 'B'},
    { titlu: 'Bătea o aripă de vânt',                nr: '11.14',   songPrevAlfID: '14.1',      songNextAlfID: '15.1'   },
    { titlu: 'Binecuvântată-i nunta',                nr: '15.1',    songPrevAlfID: '11.14',     songNextAlfID: '8.3'    },
    { titlu: 'Binecuvântată-i viaţa',                nr: '8.3',     songPrevAlfID: '15.1',      songNextAlfID: '17.3'   },
    { titlu: 'Binecuvântaţi pe Domnul',              nr: '17.3',    songPrevAlfID: '8.3',       songNextAlfID: '8.2'    },
    { titlu: 'Bine-i lângă tine',                    nr: '8.2',     songPrevAlfID: '17.3',      songNextAlfID: '4.2'    },
    { titlu: 'Biruinţa se primeşte',                 nr: '4.2',     songPrevAlfID: '8.2',       songNextAlfID: '5.1'    },
    { titlu: 'Bun medic eşti Isuse',                 nr: '5.1',     songPrevAlfID: '4.2',       songNextAlfID: '17.49'  },
    
    /* * * C - 71 * * */  
  
    { titlu: 'C'},
    { titlu: 'Ca femeia canaaneancă',                nr: '17.49',   songPrevAlfID: '5.1',       songNextAlfID: '8.4'    },
    { titlu: 'Calea-ngustă e greu de urmat',         nr: '8.4',     songPrevAlfID: '17.49',     songNextAlfID: '13.17'  },
    { titlu: 'Casa noastră e sus',                   nr: '13.17',   songPrevAlfID: '8.4',       songNextAlfID: '12.8'   },
    { titlu: 'Ca și-n ziua de Rusalii',              nr: '12.8',    songPrevAlfID: '13.17',     songNextAlfID: '4.26'   },
    { titlu: 'Călător ce ai pornit spre cer',        nr: '4.26',    songPrevAlfID: '12.8',      songNextAlfID: '17.1'   },
    { titlu: 'Călătorim prin pustia aceasta',        nr: '17.1',    songPrevAlfID: '4.26',      songNextAlfID: '6.44'   },
    { titlu: 'Când Elisei profetul',                 nr: '6.44',    songPrevAlfID: '17.1',      songNextAlfID: '17.4'   },
    { titlu: 'Când eram doar un plod',               nr: '17.4',    songPrevAlfID: '6.44',      songNextAlfID: '6.1'    },
    { titlu: 'Când eşti lângă mine',                 nr: '6.1',     songPrevAlfID: '17.4',      songNextAlfID: '16.5'   },
    { titlu: 'Când eu privesc în urma mea',          nr: '16.5',    songPrevAlfID: '6.1',       songNextAlfID: '5.2'    },
    { titlu: 'Când Isus şedea pe munte',             nr: '5.2',     songPrevAlfID: '16.5',      songNextAlfID: '11.15'  },
    { titlu: 'Când încă nu eram',                    nr: '11.15',   songPrevAlfID: '5.2',       songNextAlfID: '9.3'    },
    { titlu: 'Când mă aflu-n prezenţa Ta',           nr: '9.3',     songPrevAlfID: '11.15',     songNextAlfID: '2.2'    },
    { titlu: 'Când nici nu existam',                 nr: '2.2',     songPrevAlfID: '9.3',       songNextAlfID: '17.61'  },
    { titlu: 'Când omenirea se frământă',            nr: '17.61',   songPrevAlfID: '2.2',       songNextAlfID: '4.3'    },
    { titlu: 'Când pe marea vieţii',                 nr: '4.3',     songPrevAlfID: '17.61',     songNextAlfID: '4.4'    },
    { titlu: 'Când privesc bolta-nstelată',          nr: '4.4',     songPrevAlfID: '4.3',       songNextAlfID: '17.42'  },
    { titlu: 'Când privesc liniștea serii',          nr: '17.42',   songPrevAlfID: '4.4',       songNextAlfID: '17.55'  },
    { titlu: 'Când știu că toate-aici',              nr: '17.55',   songPrevAlfID: '17.42',     songNextAlfID: '17.5'   },
    { titlu: 'Când Te-am chemat Tu ai venit',        nr: '17.5',    songPrevAlfID: '17.55',     songNextAlfID: '4.5'    },
    { titlu: 'Când te chem în aşteptare',            nr: '4.5',     songPrevAlfID: '17.5',      songNextAlfID: '17.62'  },
    { titlu: 'Când trâmbiţa Domnului',               nr: '17.62',   songPrevAlfID: '4.5',       songNextAlfID: '7.7'    },
    { titlu: 'Când vei vedea în ziua-aceea',         nr: '7.7',     songPrevAlfID: '17.62',     songNextAlfID: '4.6'    },
    { titlu: 'Cântaţi cu toţi vă bucuraţi',          nr: '4.6',     songPrevAlfID: '7.7',       songNextAlfID: '2.3'    },
    { titlu: 'Cântăm voioşi şi suntem fericiţi',     nr: '2.3',     songPrevAlfID: '4.6',       songNextAlfID: '17.27'  },
    { titlu: 'Cântă și laudă-l',                     nr: '17.27',   songPrevAlfID: '2.3',       songNextAlfID: '8.20'   },
    { titlu: 'Cât de minunat e în lumina',           nr: '8.20',    songPrevAlfID: '17.27',     songNextAlfID: '6.16'   },
    { titlu: 'Cât de-nfricoşat e locul unde',        nr: '6.16',    songPrevAlfID: '8.20',      songNextAlfID: '9.4'    },
    { titlu: 'Câte doruri îmi frământă viaţa',       nr: '9.4',     songPrevAlfID: '6.16',      songNextAlfID: '17.63'  },
    { titlu: 'Cât încă mai curge uleiul în vas',     nr: '17.63',   songPrevAlfID: '9.4',       songNextAlfID: '2.4'    },
    { titlu: 'Cât va fi pământul sfânt va răsuna',   nr: '2.4',     songPrevAlfID: '17.63',     songNextAlfID: '17.6'   },
    { titlu: 'Ce bine-i când în piept',              nr: '17.6',    songPrevAlfID: '2.4',       songNextAlfID: '6.43'   },
    { titlu: 'Ce bine-i să mă-ncred',                nr: '6.43',    songPrevAlfID: '17.6',      songNextAlfID: '17.25'  },
    { titlu: 'Ce bun ai fost o Domnul meu',          nr: '17.25',   songPrevAlfID: '6.43',      songNextAlfID: '8.24'   },
    { titlu: 'Ce har, ce iubire în Tine găsesc',     nr: '8.24',    songPrevAlfID: '17.25',     songNextAlfID: '6.2'    },
    { titlu: 'Cei ce se încred',                     nr: '6.2',     songPrevAlfID: '8.24',      songNextAlfID: '8.25'   },
    { titlu: 'Cel ce legi răni care dor',            nr: '8.25',    songPrevAlfID: '6.2',       songNextAlfID: '6.18'   },
    { titlu: 'Cele mai frumoase momente',            nr: '6.18',    songPrevAlfID: '8.25',      songNextAlfID: '1.1'    },
    { titlu: 'Ce mare ești o Doamne',                nr: '1.1',     songPrevAlfID: '6.18',      songNextAlfID: '17.36'  },
    { titlu: 'Ce mare har să poți vedea',            nr: '17.36',   songPrevAlfID: '1.1',       songNextAlfID: '17.60'  },
    { titlu: 'Ce mult te doresc, o, Isuse',          nr: '17.60',   songPrevAlfID: '17.36',     songNextAlfID: '6.17'   },
    { titlu: 'Ce plăcută-i părtăşia',                nr: '6.17',    songPrevAlfID: '17.60',     songNextAlfID: '6.19'   },
    { titlu: 'Cerul şi pământul ne spune Cuvântul',  nr: '6.19',    songPrevAlfID: '6.17',      songNextAlfID: '16.12'  },
    { titlu: 'Chiar dacă-aici ne despărțim',         nr: '16.12',   songPrevAlfID: '6.19',      songNextAlfID: '6.3'    },
    { titlu: 'Chiar de s-ar clătina pământul',       nr: '6.3',     songPrevAlfID: '16.12',     songNextAlfID: '12.3'   },
    { titlu: 'Cine cine ne va despărţi',             nr: '12.3',    songPrevAlfID: '6.3',       songNextAlfID: '2.5'    },
    { titlu: 'Cine oare e ca Dumnezeul meu',         nr: '2.5',     songPrevAlfID: '12.3',      songNextAlfID: '13.18'  },
    { titlu: 'Cine sunt aceştia Doamne',             nr: '13.18',   songPrevAlfID: '2.5',       songNextAlfID: '6.20'   },
    { titlu: 'Cine vrea să simtă azi pe Domnul',     nr: '6.20',    songPrevAlfID: '13.18',     songNextAlfID: '2.30'   },
    { titlu: 'Cinste şi onoare',                     nr: '2.30',    songPrevAlfID: '6.20',      songNextAlfID: '14.2'   },
    { titlu: 'Copiii să vină la El',                 nr: '14.2',    songPrevAlfID: '2.30',      songNextAlfID: '7.5'    },
    { titlu: 'Cred în Dumnezeu ca Tată',             nr: '7.5',     songPrevAlfID: '14.2',      songNextAlfID: '11.3'   },
    { titlu: 'Crucea-i ascultarea',                  nr: '11.3',    songPrevAlfID: '7.5',       songNextAlfID: '1.10'   },
    { titlu: 'Cu aripi nevăzute',                    nr: '1.10',    songPrevAlfID: '11.3',      songNextAlfID: '17.20'  },
    { titlu: 'Cu bucurie eu cânt Domnului',          nr: '17.20',   songPrevAlfID: '1.10',      songNextAlfID: '11.4'   },
    { titlu: 'Cu fruntea-nsângerată',                nr: '11.4',    songPrevAlfID: '17.20',     songNextAlfID: '13.19'  },
    { titlu: 'Cu gândul la viaţa eternă',            nr: '13.19',   songPrevAlfID: '11.4',      songNextAlfID: '6.21'   },
    { titlu: 'Cu haina iubirii s-acopăr mereu',      nr: '6.21',    songPrevAlfID: '13.19',     songNextAlfID: '8.5'    },
    { titlu: 'Cu Isus vreau să-mi continui calea',   nr: '8.5',     songPrevAlfID: '6.21',      songNextAlfID: '2.6'    },
    { titlu: 'Cu încredere Dumnezeule',              nr: '2.6',     songPrevAlfID: '8.5',       songNextAlfID: '4.7'    },
    { titlu: 'Cu lacrimi noi ne bucurăm',            nr: '4.7',     songPrevAlfID: '2.6',       songNextAlfID: '1.11'   },
    { titlu: 'Cu lanțuri nevăzute-am fost legat',    nr: '1.11',    songPrevAlfID: '4.7',       songNextAlfID: '2.8'    },
    { titlu: 'Cum aş putea să tac',                  nr: '2.8',     songPrevAlfID: '1.11',      songNextAlfID: '2.9'    },
    { titlu: 'Cum să nu-l slăvesc pe  Domnul meu',   nr: '2.9',     songPrevAlfID: '2.8',       songNextAlfID: '13.30'  },
    { titlu: 'Cum stăm noi aşa nepăsători',          nr: '13.30',   songPrevAlfID: '2.9',       songNextAlfID: '13.31'  },
    { titlu: 'Cum tremură-n mână săgeata',           nr: '13.31',   songPrevAlfID: '13.30',     songNextAlfID: '13.42'  },
    { titlu: 'Curând Domnul slavei va reveni',       nr: '13.42',   songPrevAlfID: '13.31',     songNextAlfID: '13.20'  },
    { titlu: 'Cu suflet mă grăbesc',                 nr: '13.20',   songPrevAlfID: '13.42',     songNextAlfID: '8.6'    },
    { titlu: 'Cu Tine Doamne Isuse',                 nr: '8.6',     songPrevAlfID: '13.20',     songNextAlfID: '8.23'   },
    { titlu: 'Cu Tine doar cu Tine',                 nr: '8.23',    songPrevAlfID: '8.6',       songNextAlfID: '2.7'    },
    { titlu: 'Cu Tine mă laud mereu',                nr: '2.7',     songPrevAlfID: '8.23',      songNextAlfID: '6.22'   },
  
    /* * * D - 50 * * */  
  
    { titlu: 'D'},
    { titlu: 'Dac-ai ajuns în ape liniștite',        nr: '6.22',    songPrevAlfID: '2.7',       songNextAlfID: '7.6'    },
    { titlu: 'Dac-aş fi stăpân pe lumea-ntreagă',    nr: '7.6',     songPrevAlfID: '6.22',      songNextAlfID: '17.7'   },
    { titlu: 'Dacă-aş spune că iubesc',              nr: '17.7',    songPrevAlfID: '7.6',       songNextAlfID: '13.32'  },
    { titlu: 'Dacă Domnul chiar la noapte ar veni',  nr: '13.32',   songPrevAlfID: '17.7',      songNextAlfID: '6.23'   },
    { titlu: 'Dacă pleci la luptă frate',            nr: '6.23',    songPrevAlfID: '13.32',     songNextAlfID: '7.14'   },
    { titlu: 'Dacă toate trec în viaţă',             nr: '7.14',    songPrevAlfID: '6.23',      songNextAlfID: '8.7'    },
    { titlu: 'Dacă-ţi spune cineva',                 nr: '8.7',     songPrevAlfID: '7.14',      songNextAlfID: '7.15'   },
    { titlu: 'Dacă vei vrea să primeşti',            nr: '7.15',    songPrevAlfID: '8.7',       songNextAlfID: '8.8'    },
    { titlu: 'Dă-ne putere Doamne azi îţi cerem',    nr: '8.8',     songPrevAlfID: '7.15',      songNextAlfID: '8.26'   },
    { titlu: 'Dă-ți solzii de pe ochi',              nr: '8.26',    songPrevAlfID: '8.8',       songNextAlfID: '7.30'   },
    { titlu: 'De-atâta timp ești așteptat',          nr: '7.30',    songPrevAlfID: '8.26',      songNextAlfID: '7.29'   },
    { titlu: 'De-atâtea ori ai auzit Cuvântul',      nr: '7.29',    songPrevAlfID: '7.30',      songNextAlfID: '6.24'   },
    { titlu: 'De-atâtea ori te afli',                nr: '6.24',    songPrevAlfID: '7.29',      songNextAlfID: '11.5'   },
    { titlu: 'De-atâtea ori Te-am întristat',        nr: '11.5',    songPrevAlfID: '6.24',      songNextAlfID: '7.11'   },
    { titlu: 'De când cu Tine, Doamne eu trăiesc',   nr: '7.11',    songPrevAlfID: '11.5',      songNextAlfID: '8.9'    },
    { titlu: 'De când Te cunosc Isuse',              nr: '8.9',     songPrevAlfID: '7.11',      songNextAlfID: '8.10'   },
    { titlu: 'De când trăiesc o Doamne lângă Tine',  nr: '8.10',    songPrevAlfID: '8.9',       songNextAlfID: '9.5'    },
    { titlu: 'De ce anii mei se duc în vânt',        nr: '9.5',     songPrevAlfID: '8.10',      songNextAlfID: '17.8'   },
    { titlu: 'De ce mai plângi',                     nr: '17.8',    songPrevAlfID: '9.5',       songNextAlfID: '4.8'    },
    { titlu: 'De ce porţi tu povara',                nr: '4.8',     songPrevAlfID: '17.8',      songNextAlfID: '13.3'   },
    { titlu: 'De dorul tău Isus iubit',              nr: '13.3',    songPrevAlfID: '4.8',       songNextAlfID: '5.3'    },
    { titlu: 'De eşti trudit şi ţi-e grea povara',   nr: '5.3',     songPrevAlfID: '13.3',      songNextAlfID: '10.3'   },
    { titlu: 'Demult profeţii-au profeţit',          nr: '10.3',    songPrevAlfID: '5.3',       songNextAlfID: '17.74'  },
    { titlu: 'De mult timp rătăceam în păcat',       nr: '17.74',   songPrevAlfID: '10.3',      songNextAlfID: '2.31'   },
    { titlu: 'De n-ai fi Tu în orice floare',        nr: '2.31',    songPrevAlfID: '17.74',     songNextAlfID: '13.4'   },
    { titlu: 'De ţara Ta Doamne',                    nr: '13.4',    songPrevAlfID: '2.31',      songNextAlfID: '10.4'   },
    { titlu: 'Din a cerului mărire',                 nr: '10.4',    songPrevAlfID: '13.4',      songNextAlfID: '6.45'   },
    { titlu: 'Din neam în neam eşti adăpost',        nr: '6.45',    songPrevAlfID: '10.4',      songNextAlfID: '11.6'   },
    { titlu: 'Din slava cerului sublim',             nr: '11.6',    songPrevAlfID: '6.45',      songNextAlfID: '14.3'   },
    { titlu: 'Din slava strălucirilor albastre',     nr: '14.3',    songPrevAlfID: '11.6',      songNextAlfID: '17.77'  },
    { titlu: 'Din stânci țâșnesc',                   nr: '17.77',   songPrevAlfID: '14.3',      songNextAlfID: '17.9'   },
    { titlu: 'Din strălucire jos ai venit',          nr: '17.9',    songPrevAlfID: '17.77',     songNextAlfID: '14.4'   },
    { titlu: 'Din ţesuturi nevăzute',                nr: '14.4',    songPrevAlfID: '17.9',      songNextAlfID: '17.57'  },
    { titlu: 'Din valea plângerii cu dor',           nr: '17.57',   songPrevAlfID: '14.4',      songNextAlfID: '1.2'    },
    { titlu: 'Din veşnicie-n veşnicie',              nr: '1.2',     songPrevAlfID: '17.57',     songNextAlfID: '12.4'   },
    { titlu: 'Doamne dă-ne-nţelepciune',             nr: '12.4',    songPrevAlfID: '1.2',       songNextAlfID: '4.9'    },
    { titlu: 'Doamne rugăciunea mea',                nr: '4.9',     songPrevAlfID: '12.4',      songNextAlfID: '1.3'    },
    { titlu: 'Domn al domnilor rege-al regilor',     nr: '1.3',     songPrevAlfID: '4.9',       songNextAlfID: '7.16'   },
    { titlu: 'Domnul bate la uşa ta',                nr: '7.16',    songPrevAlfID: '1.3',       songNextAlfID: '6.4'    },
    { titlu: 'Domnul doarme în corăbioară',          nr: '6.4',     songPrevAlfID: '7.16',      songNextAlfID: '2.10'   },
    { titlu: 'Domnul e aici',                        nr: '2.10',    songPrevAlfID: '6.4',       songNextAlfID: '17.10'  },
    { titlu: 'Domnul e al meu păstor',               nr: '17.10',   songPrevAlfID: '2.10',      songNextAlfID: '6.5'    },
    { titlu: 'Domnul împărăţeşte',                   nr: '6.5',     songPrevAlfID: '17.10',     songNextAlfID: '2.32'   },
    { titlu: 'Domnu-mi poartă grija',                nr: '2.32',    songPrevAlfID: '6.5',       songNextAlfID: '15.2'   },
    { titlu: 'Dragoste dragoste',                    nr: '15.2',    songPrevAlfID: '2.32',      songNextAlfID: '4.29'   },
    { titlu: 'Dumnezeu e adăpostul',                 nr: '4.29',    songPrevAlfID: '15.2',      songNextAlfID: '8.11'   },
    { titlu: 'Dumnezeu m-a creat cu iubire',         nr: '8.11',    songPrevAlfID: '4.29',      songNextAlfID: '7.17'   },
    { titlu: 'Dumnezeu ne cheamă pe toţi',           nr: '7.17',    songPrevAlfID: '8.11',      songNextAlfID: '6.6'    },
    { titlu: 'Dumnezeu ne este adăpost',             nr: '6.6',     songPrevAlfID: '7.17',      songNextAlfID: '17.51'  },
    { titlu: 'După două mii de ani',                 nr: '17.51',   songPrevAlfID: '6.6',       songNextAlfID: '11.7'   },
  
    /* * * E - 18 * * */  
  
    { titlu: 'E'},
    { titlu: 'E mare judecată la Gabata',            nr: '11.7',    songPrevAlfID: '17.51',     songNextAlfID: '6.25'   },
    { titlu: 'E mult prea scumpă viaţa asta',        nr: '6.25',    songPrevAlfID: '11.7',      songNextAlfID: '10.10'  },
    { titlu: 'E nașterea Domnului',                  nr: '10.10',   songPrevAlfID: '6.25',      songNextAlfID: '17.64'  },
    { titlu: 'E-o luptă, viața e-o luptă',           nr: '17.64',   songPrevAlfID: '10.10',     songNextAlfID: '12.5'   },
    { titlu: 'E secetă în ţară',                     nr: '12.5',    songPrevAlfID: '17.64',     songNextAlfID: '16.9'   },
    { titlu: 'Este-n cer o țară',                    nr: '16.9',    songPrevAlfID: '12.5',      songNextAlfID: '17.65'  },
    { titlu: 'Este clipa încercării',                nr: '17.65',   songPrevAlfID: '16.9',      songNextAlfID: '7.18'   },
    { titlu: 'Este-o zi de har totul e în dar',      nr: '7.18',    songPrevAlfID: '17.65',     songNextAlfID: '1.4'    },
    { titlu: 'Eşti necuprins de timp şi spaţiu',     nr: '1.4',     songPrevAlfID: '7.18',      songNextAlfID: '13.44'  },
    { titlu: 'E tot mai grea în noapte așteptarea',  nr: '13.44',   songPrevAlfID: '1.4',       songNextAlfID: '5.4'    },
    { titlu: 'Eu cred în vindecările divine',        nr: '5.4',     songPrevAlfID: '13.44',     songNextAlfID: '4.10'   },
    { titlu: 'Eu mă încred în Domnul meu',           nr: '4.10',    songPrevAlfID: '5.4',       songNextAlfID: '4.11'   },
    { titlu: 'Eu n-am să te las ca să pieri',        nr: '4.11',    songPrevAlfID: '4.10',      songNextAlfID: '13.5'   },
    { titlu: 'Eu sunt Alfa şi Omega',                nr: '13.5',    songPrevAlfID: '4.11',      songNextAlfID: '8.12'   },
    { titlu: 'Eu sunt calea-a spus Domnul Isus',     nr: '8.12',    songPrevAlfID: '13.5',      songNextAlfID: '17.75'  },
    { titlu: 'Eu sunt din acei care',                nr: '17.75',   songPrevAlfID: '8.12',      songNextAlfID: '7.33'   },
    { titlu: 'Există după moarte o veşnicie',        nr: '7.33',    songPrevAlfID: '17.75',     songNextAlfID: '7.19'   },
    { titlu: 'Există un timp pentru toate',          nr: '7.19',    songPrevAlfID: '7.33',      songNextAlfID: '7.20'   },
  
    /* * * F - 4 * * */  
  
    { titlu: 'F'},
    { titlu: 'Fără de Hristos tu nu poți trăi',      nr: '7.20',    songPrevAlfID: '7.19',      songNextAlfID: '13.33'  },
    { titlu: 'Fecioarele-nţelepte',                  nr: '13.33',   songPrevAlfID: '7.20',      songNextAlfID: '17.34'  },
    { titlu: 'Frați iubiți răsună iarăși',           nr: '17.34',   songPrevAlfID: '13.33',     songNextAlfID: '6.7'    },
    { titlu: 'Fraţilor credinţa noastră s-o păzim',  nr: '6.7',     songPrevAlfID: '17.34',     songNextAlfID: '2.11'   },
  
    /* * * G - 5 * * */  
  
    { titlu: 'G'},
    { titlu: 'Gata-mi este inima să cânte',          nr: '2.11',    songPrevAlfID: '6.7',       songNextAlfID: '17.29'  },
    { titlu: 'Gândește-te-ntruna la cele de sus',    nr: '17.29',   songPrevAlfID: '2.11',      songNextAlfID: '7.21'   },
    { titlu: 'Glasul Tatălui ceresc',                nr: '7.21',    songPrevAlfID: '17.29',     songNextAlfID: '11.8'   },
    { titlu: 'Golgota, loc de durere',               nr: '11.8',    songPrevAlfID: '7.21',      songNextAlfID: '9.6'    },
    { titlu: 'Grădină minunată a iubirii',           nr: '9.6',     songPrevAlfID: '11.8',      songNextAlfID: '13.6'   },
  
    /* * * H - 1 * * */  
  
    { titlu: 'H'},
    { titlu: 'Haideți creștini azi cu toți',         nr: '13.6',    songPrevAlfID: '9.6',       songNextAlfID: '7.28'   },
  
    /* * * I - 18 * * */  
  
    { titlu: 'I'},
    { titlu: 'Iată cerul așteaptă candidați',        nr: '7.28',    songPrevAlfID: '13.6',      songNextAlfID: '8.13'   },
    { titlu: 'Iată ce sunt, iată ce am',             nr: '8.13',    songPrevAlfID: '7.28',      songNextAlfID: '2.12'   },
    { titlu: 'Iată-mă Isuse stau naintea Ta',        nr: '2.12',    songPrevAlfID: '8.13',      songNextAlfID: '7.8'    },
    { titlu: 'Iată se vesteşte ca niciodată',        nr: '7.8',     songPrevAlfID: '2.12',      songNextAlfID: '15.3'   },
    { titlu: 'Iată ziua ce-aţi cerut-o',             nr: '15.3',    songPrevAlfID: '7.8',       songNextAlfID: '17.32'  },
    { titlu: 'Ierusalim cetate-mpodobită',           nr: '17.32',   songPrevAlfID: '15.3',      songNextAlfID: '2.13'   },
    { titlu: 'Inima din pieptul meu',                nr: '2.13',    songPrevAlfID: '17.32',     songNextAlfID: '3.1'    },
    { titlu: 'Inima-mi cântă de bucurie',            nr: '3.1',     songPrevAlfID: '2.13',      songNextAlfID: '2.14'   },
    { titlu: 'Inima mi-e gata să-ți cânte',          nr: '2.14',    songPrevAlfID: '3.1',       songNextAlfID: '15.5'   },
    { titlu: 'Isuse drag azi două inimi',            nr: '15.5',    songPrevAlfID: '2.14',      songNextAlfID: '15.6'   },
    { titlu: 'Isuse dragostea-ţi din cer',           nr: '15.6',    songPrevAlfID: '15.5',      songNextAlfID: '2.33'   },
    { titlu: 'Isuse, numele Tău sfânt',              nr: '2.33',    songPrevAlfID: '15.6',      songNextAlfID: '8.21'   },
    { titlu: 'Isuse, prieten drag',                  nr: '8.21',    songPrevAlfID: '2.33',      songNextAlfID: '17.17'  },
    { titlu: 'Isus e prietenul cel bun',             nr: '17.17',   songPrevAlfID: '8.21',      songNextAlfID: '17.50'  },
    { titlu: 'Isuse scump, iubire nepătrunsă',       nr: '17.50',   songPrevAlfID: '17.17',     songNextAlfID: '15.4'   },
    { titlu: 'Isus iubit când vei veni',             nr: '15.4',    songPrevAlfID: '17.50',     songNextAlfID: '4.27'   },
    { titlu: 'Isus ne-a salvat de la moarte',        nr: '4.27',    songPrevAlfID: '15.4',      songNextAlfID: '7.9'    },
    { titlu: 'Isus păcatul ne-a iertat',             nr: '7.9',     songPrevAlfID: '4.27',      songNextAlfID: '11.18'  },
  
    /* * * Î - 26 * * */  
  
    { titlu: 'Î'},
    { titlu: 'Împlinește-ți misiunea',               nr: '11.18',   songPrevAlfID: '7.9',       songNextAlfID: '15.7'   },
    { titlu: 'Înaintea ta venim scump salvator',     nr: '15.7',    songPrevAlfID: '11.18',     songNextAlfID: '10.5'   },
    { titlu: 'În Betleem azi e o mare minune',       nr: '10.5',    songPrevAlfID: '15.7',      songNextAlfID: '7.22'   },
    { titlu: 'Încă mai este loc la cruce',           nr: '7.22',    songPrevAlfID: '10.5',      songNextAlfID: '17.43'  },
    { titlu: 'Încă mai răsună glasul Domnului',      nr: '17.43',   songPrevAlfID: '7.22',      songNextAlfID: '4.12'   },
    { titlu: 'În clipe de grea încercare',           nr: '4.12',    songPrevAlfID: '17.43',     songNextAlfID: '4.13'   },
    { titlu: 'Încrede-te Israel încrede-te mereu',   nr: '4.13',    songPrevAlfID: '4.12',      songNextAlfID: '13.7'   },
    { titlu: 'În curând noi vom pleca',              nr: '13.7',    songPrevAlfID: '4.13',      songNextAlfID: '16.6'   },
    { titlu: 'În curând s-a termina',                nr: '16.6',    songPrevAlfID: '13.7',      songNextAlfID: '3.2'    },
    { titlu: 'Îndreaptă-mi spre Tine inima',         nr: '3.2',     songPrevAlfID: '16.6',      songNextAlfID: '9.7'    },
    { titlu: 'Îngenuncheat cu faţa-n palme',         nr: '9.7',     songPrevAlfID: '3.2',       songNextAlfID: '10.2'   },
    { titlu: 'În oraşul Betleem ni s-a născut',      nr: '10.2',    songPrevAlfID: '9.7',       songNextAlfID: '6.8'    },
    { titlu: 'În orice clipă-n viaţa mea',           nr: '6.8',     songPrevAlfID: '10.2',      songNextAlfID: '2.15'   },
    { titlu: 'În orice făptură şi-orice creatură',   nr: '2.15',    songPrevAlfID: '6.8',       songNextAlfID: '6.26'   },
    { titlu: 'În orice suflet e-un tainic dor',      nr: '6.26',    songPrevAlfID: '2.15',      songNextAlfID: '6.27'   },
    { titlu: 'În slăvitu-ţi nume mare',              nr: '6.27',    songPrevAlfID: '6.26',      songNextAlfID: '7.34'   },
    { titlu: 'Întoarce-te-acum, la al tău Făcător',  nr: '7.34',    songPrevAlfID: '6.27',      songNextAlfID: '10.6'   },
    { titlu: 'Într-o iesle într-un grajd',           nr: '10.6',    songPrevAlfID: '7.34',      songNextAlfID: '6.28'   },
    { titlu: 'Într-o zi pe Ieremia',                 nr: '6.28',    songPrevAlfID: '10.6',      songNextAlfID: '10.7'   },
    { titlu: 'Într-un sat ne-nsemnat din Iudeea',    nr: '10.7',    songPrevAlfID: '6.28',      songNextAlfID: '6.29'   },
    { titlu: 'Înţelepţii lumii',                     nr: '6.29',    songPrevAlfID: '10.7',      songNextAlfID: '17.66'  },
    { titlu: 'În valea cu umbrele morții',           nr: '17.66',   songPrevAlfID: '6.29',      songNextAlfID: '2.16'   },
    { titlu: 'În veci slăvit fie Domnul',            nr: '2.16',    songPrevAlfID: '17.66',     songNextAlfID: '17.37'  },
    { titlu: 'În zare spre noi',                     nr: '17.37',   songPrevAlfID: '2.16',      songNextAlfID: '13.21'  },
    { titlu: 'În ziua de apoi',                      nr: '13.21',   songPrevAlfID: '17.37',     songNextAlfID: '3.7'    },
    { titlu: 'Îți aducem Doamne mulțumire',          nr: '3.7',     songPrevAlfID: '13.21',     songNextAlfID: '17.33'  },
    
    /* * * J - 1 * * */  
  
    { titlu: 'J'},
    { titlu: 'Judecata va începe',                   nr: '17.33',   songPrevAlfID: '3.7',       songNextAlfID: '6.30'   },
  
    /* * * L - 8 * * */  
  
    { titlu: 'L'},
    { titlu: 'La ce sunt bune toate-n viaţă',        nr: '12.6',    songPrevAlfID: '17.33',     songNextAlfID: '9.8'    },
    { titlu: 'La crucea de la Golgota',              nr: '9.8',     songPrevAlfID: '12.6',      songNextAlfID: '8.14'   },
    { titlu: 'La orice pas ce-n viaţă îl fac',       nr: '8.14',    songPrevAlfID: '9.8',       songNextAlfID: '5.5'    },
    { titlu: 'La poarta cea frumoasă sta ologul',    nr: '5.5',     songPrevAlfID: '8.14',      songNextAlfID: '9.9'    },
    { titlu: 'La Tine Doamne îmi înalţ sufletul',    nr: '9.9',     songPrevAlfID: '5.5',       songNextAlfID: '6.30'   },
    { titlu: 'Laudă astăzi pe Domnul',               nr: '6.30',    songPrevAlfID: '9.9',       songNextAlfID: '4.14'   },
    { titlu: 'La umbra aripilor tale',               nr: '4.14',    songPrevAlfID: '6.30',      songNextAlfID: '14.6'   },
    { titlu: 'Lăsaţi copilaşii să vină la mine',     nr: '14.6',    songPrevAlfID: '4.14',      songNextAlfID: '9.10'   },
  
    /* * * M - 26 * * */  
  
    { titlu: 'M'},
    { titlu: 'Mai adu-ţi, adu-ţi aminte',            nr: '9.10',    songPrevAlfID: '14.6',      songNextAlfID: '17.54'  },
    { titlu: 'M-ai binecuvântat o, Doamne',          nr: '17.54',   songPrevAlfID: '9.10',      songNextAlfID: '17.56'  },
    { titlu: 'Mai e puțin din noapte',               nr: '17.56',   songPrevAlfID: '17.54',     songNextAlfID: '13.45'  },
    { titlu: 'Mai e puțin pe această cale',          nr: '13.45',   songPrevAlfID: '17.56',     songNextAlfID: '13.8'   },
    { titlu: 'Mai e puţin şi va veni Mesia',         nr: '13.8',    songPrevAlfID: '13.45',     songNextAlfID: '17.11'  },
    { titlu: 'Mai este mult Isuse drag',             nr: '17.11',   songPrevAlfID: '13.8',      songNextAlfID: '13.34'  },
    { titlu: 'Mai este mult străjer din noapte',     nr: '13.34',   songPrevAlfID: '17.11',     songNextAlfID: '17.12'  },
    { titlu: 'Mai mult acum ca niciodată',           nr: '17.12',   songPrevAlfID: '13.34',     songNextAlfID: '17.67'  },
    { titlu: 'Mai mult decât aşteaptă dimineaţa',    nr: '17.67',   songPrevAlfID: '17.12',     songNextAlfID: '13.9'   },
    { titlu: 'Mai mult decât aşteaptă străjerul',    nr: '13.9',    songPrevAlfID: '17.67',     songNextAlfID: '15.8'   },
    { titlu: 'Mai presus de orice',                  nr: '15.8',    songPrevAlfID: '13.9',      songNextAlfID: '17.40'  },
    { titlu: 'Mai scoate Doamne oameni ca Ilie',     nr: '17.40',   songPrevAlfID: '15.8',      songNextAlfID: '13.22'  },
    { titlu: 'Mai sus de stele mai sus de nori',     nr: '13.22',   songPrevAlfID: '17.40',     songNextAlfID: '7.10'   },
    { titlu: 'M-am hotărât să vin la Tine',          nr: '7.10',    songPrevAlfID: '13.22',     songNextAlfID: '1.5'    },
    { titlu: 'Mare e Domnul meu',                    nr: '1.5',     songPrevAlfID: '7.10',      songNextAlfID: '17.68'  },
    { titlu: 'Mă gândesc ades la Tine',              nr: '17.68',   songPrevAlfID: '1.5',       songNextAlfID: '8.15'   },
    { titlu: 'Mă lupt să trăiesc adevărul',          nr: '8.15',    songPrevAlfID: '17.68',     songNextAlfID: '16.10'  },
    { titlu: 'Mergem pe calea vieţii',               nr: '16.10',   songPrevAlfID: '8.15',      songNextAlfID: '6.31'   },
    { titlu: 'Mergeţi vestiţi',                      nr: '6.31',    songPrevAlfID: '16.10',     songNextAlfID: '2.17'   },
    { titlu: 'Meriţi toată slava',                   nr: '2.17',    songPrevAlfID: '6.31',      songNextAlfID: '6.9'    },
    { titlu: 'Mi-am pus nădejdea-n Tine',            nr: '6.9',     songPrevAlfID: '2.17',      songNextAlfID: '13.24'  },
    { titlu: 'Mi-e dor mi-e dor de-acasă',           nr: '13.24',   songPrevAlfID: '6.9',       songNextAlfID: '13.23'  },
    { titlu: 'Mi-e dor mi-e dor de casa mea',        nr: '13.23',   songPrevAlfID: '13.24',     songNextAlfID: '13.46'  },
    { titlu: 'Mii de ani ce-au trecut',              nr: '13.46',   songPrevAlfID: '13.23',     songNextAlfID: '3.3'    },
    { titlu: 'Mulţumeşte-n miez de noapte',          nr: '3.3',     songPrevAlfID: '13.46',     songNextAlfID: '17.69'  },
    { titlu: 'Mulţumim Domnului pentru ziua',        nr: '17.69',   songPrevAlfID: '3.3',       songNextAlfID: '17.46'  },
  
    /* * * N - 23 * * */  
  
    { titlu: 'N'},
    { titlu: 'N-am să pot eu, Doamne, niciodată',    nr: '17.46',   songPrevAlfID: '17.69',     songNextAlfID: '17.30'  },
    { titlu: 'Navigăm pe oceanele lumii',            nr: '17.30',   songPrevAlfID: '17.46',     songNextAlfID: '17.44'  },
    { titlu: 'Ne-ai fost, Doamne, ajutor',           nr: '3.6',     songPrevAlfID: '17.44',     songNextAlfID: '13.35'  },
    { titlu: 'Ne cheamă Dumnezeu pe toţi',           nr: '13.35',   songPrevAlfID: '3.6',       songNextAlfID: '17.70'  },
    { titlu: 'Nimic nu mă va despărţi',              nr: '17.70',   songPrevAlfID: '13.35',     songNextAlfID: '12.9'   },
    { titlu: 'Noi cerem Duhul Sfânt să vină',        nr: '12.9',    songPrevAlfID: '17.70',     songNextAlfID: '13.10'  },
    { titlu: 'Nu aceasta e menirea noastră',         nr: '13.10',   songPrevAlfID: '12.9',      songNextAlfID: '6.32'   },
    { titlu: 'Nu e nici un om în lume',              nr: '6.32',    songPrevAlfID: '13.10',     songNextAlfID: '1.12'   },
    { titlu: 'Nu este ca tine vreo stâncă',          nr: '1.12',    songPrevAlfID: '6.32',      songNextAlfID: '4.16'   },
    { titlu: 'Nu-i nimeni ca Tine Isus',             nr: '4.16',    songPrevAlfID: '1.12',      songNextAlfID: '14.5'   },
    { titlu: 'Nu-i uşor să fii părinte',             nr: '14.5',    songPrevAlfID: '4.16',      songNextAlfID: '13.11'  },
    { titlu: 'Nu mai este mult',                     nr: '13.11',   songPrevAlfID: '14.5',      songNextAlfID: '7.23'   },
    { titlu: 'Numai o dată tu trăieşti',             nr: '7.23',    songPrevAlfID: '13.11',     songNextAlfID: '2.18'   },
    { titlu: 'Nu pot uita vreodată',                 nr: '2.18',    songPrevAlfID: '7.23',      songNextAlfID: '17.48'  },
    { titlu: 'Nu se poate să nu fie',                nr: '17.48',   songPrevAlfID: '2.18',      songNextAlfID: '6.33'   },
    { titlu: 'Nu sunt bogat în astă lume',           nr: '6.33',    songPrevAlfID: '17.48',     songNextAlfID: '17.52'  },
    { titlu: 'Nu ştiam că-n juru-mi sunt izvoare',   nr: '17.52',   songPrevAlfID: '6.33',      songNextAlfID: '7.35'   },
    { titlu: 'Nu te mai vreau lume',                 nr: '7.35',    songPrevAlfID: '17.52',     songNextAlfID: '5.6'    },
    { titlu: 'Nu te-ndoi ci crede oricât de grea',   nr: '5.6',     songPrevAlfID: '7.35',      songNextAlfID: '8.16'   },
    { titlu: 'Nu-ţi cer nimic în a mea rugă',        nr: '8.16',    songPrevAlfID: '5.6',       songNextAlfID: '2.19'   },
    { titlu: 'Nu va fi o veste mai frumoasă',        nr: '2.19',    songPrevAlfID: '8.16',      songNextAlfID: '4.15'   },
    { titlu: 'Nu vă-ngrijoraţi de viaţa voastră',    nr: '4.15',    songPrevAlfID: '2.19',      songNextAlfID: '13.36'  },
  
    /* * * O - 16 * * */  
  
    { titlu: 'O'},
    { titlu: 'O ascultaţi o ascultaţi',              nr: '13.36',   songPrevAlfID: '4.15',      songNextAlfID: '17.26'  },
    { titlu: 'O ce frumoasă fi-va cântarea',         nr: '17.26',   songPrevAlfID: '13.36',     songNextAlfID: '4.17'   },
    { titlu: 'O, ce har să ştii că Domnul',          nr: '4.17',    songPrevAlfID: '17.26',     songNextAlfID: '17.71'  },
    { titlu: 'O, ce minunată este viaţa',            nr: '17.71',   songPrevAlfID: '4.17',      songNextAlfID: '11.9'   },
    { titlu: 'O, ce valuri de-ndurare',              nr: '11.9',    songPrevAlfID: '17.71',     songNextAlfID: '10.8'   },
    { titlu: 'O, ce veste îmbucurătoare',            nr: '10.8',    songPrevAlfID: '11.9',      songNextAlfID: '17.53'  },
    { titlu: 'Ochii mei te vor vedea',               nr: '17.53',   songPrevAlfID: '10.8',      songNextAlfID: '16.7'   },
    { titlu: 'O Doamne bun ziua de mâine',           nr: '16.7',    songPrevAlfID: '17.53',     songNextAlfID: '6.34'   },
    { titlu: 'O, Doamne fă să vie',                  nr: '6.34',    songPrevAlfID: '16.7',      songNextAlfID: '1.6'    },
    { titlu: 'O laudă e cântarea mea',               nr: '1.6',     songPrevAlfID: '6.34',      songNextAlfID: '3.4'    },
    { titlu: 'Omule ce-n lume astăzi te trudeşti',   nr: '3.4',     songPrevAlfID: '1.6',       songNextAlfID: '13.37'  },
    { titlu: 'Omu-n lume-i ca vaporul',              nr: '13.37',   songPrevAlfID: '3.4',       songNextAlfID: '7.24'   },
    { titlu: 'O poartă e deschisă',                  nr: '7.24',    songPrevAlfID: '13.37',     songNextAlfID: '12.7'   },
    { titlu: 'O putere nemaipomenită',               nr: '12.7',    songPrevAlfID: '7.24',      songNextAlfID: '13.12'  },
    { titlu: 'Oricât de lungă-i noaptea',            nr: '13.12',   songPrevAlfID: '12.7',      songNextAlfID: '2.20'   },
    { titlu: 'O stâncă eu am',                       nr: '2.20',    songPrevAlfID: '13.12',     songNextAlfID: '6.35'   },
  
    /* * * P - 24 * * */  
  
    { titlu: 'P'},
    { titlu: 'Păstraţi legătura frăţească',          nr: '6.35',    songPrevAlfID: '2.20',      songNextAlfID: '17.13'  },
    { titlu: 'Până-aici Dumnezeu ne-a ajutat',       nr: '17.13',   songPrevAlfID: '6.35',      songNextAlfID: '13.13'  },
    { titlu: 'Pe-acest pământ pe-această cale',      nr: '13.13',   songPrevAlfID: '17.13',     songNextAlfID: '17.24'  },
    { titlu: 'Pe calea spre cer',                    nr: '17.24',   songPrevAlfID: '13.13',     songNextAlfID: '8.27'   },
    { titlu: 'Pe drumul către patria iubirii',       nr: '8.27',    songPrevAlfID: '17.24',     songNextAlfID: '17.14'  },
    { titlu: 'Pe marea vieţii vâslesc zi de zi',     nr: '17.14',   songPrevAlfID: '8.27',      songNextAlfID: '17.21'  },
    { titlu: 'Pe mare-n corabie',                    nr: '17.21',   songPrevAlfID: '17.14',     songNextAlfID: '11.10'  },
    { titlu: 'Pentru-a mea răscumpărare',            nr: '11.10',   songPrevAlfID: '17.21',     songNextAlfID: '9.11'   },
    { titlu: 'Pentru cine-ţi bate bate',             nr: '9.11',    songPrevAlfID: '11.10',     songNextAlfID: '11.11'  },
    { titlu: 'Pentru mine Dumnezeu',                 nr: '11.11',   songPrevAlfID: '9.11',      songNextAlfID: '16.8'   },
    { titlu: 'Pentru noi e taină mare',              nr: '16.8',    songPrevAlfID: '11.11',     songNextAlfID: '1.13'   },
    { titlu: 'Pentru tine vreau, Isuse',             nr: '1.13',    songPrevAlfID: '16.8',      songNextAlfID: '13.38'  },
    { titlu: 'Pe pământ avem o luptă',               nr: '13.38',   songPrevAlfID: '1.13',      songNextAlfID: '17.47'  },
    { titlu: 'Pe șesul arid, nisipos',               nr: '17.47',   songPrevAlfID: '13.38',     songNextAlfID: '8.17'   },
    { titlu: 'Pe Tini te laud Isuse',                nr: '8.17',    songPrevAlfID: '17.47',     songNextAlfID: '11.16'  },
    { titlu: 'Pe un deal pe o cruce',                nr: '11.16',   songPrevAlfID: '8.17',      songNextAlfID: '6.36'   },
    { titlu: 'Pe vremuri stă Iosua',                 nr: '6.36',    songPrevAlfID: '11.16',     songNextAlfID: '7.25'   },
    { titlu: 'Poate eşti iubit',                     nr: '7.25',    songPrevAlfID: '6.36',      songNextAlfID: '4.18'   },
    { titlu: 'Porumbiţă scumpă-ascunsă',             nr: '4.18',    songPrevAlfID: '7.25',      songNextAlfID: '13.25'  },
    { titlu: 'Pribegi ca Moise în pustie',           nr: '13.25',   songPrevAlfID: '4.18',      songNextAlfID: '13.14'  },
    { titlu: 'Prin arşiţa vremii',                   nr: '13.14',   songPrevAlfID: '13.25',     songNextAlfID: '11.12'  },
    { titlu: 'Prin harul care Doamne mi l-ai dat',   nr: '17.44',   songPrevAlfID: '17.30',     songNextAlfID: '3.6'    },
    { titlu: 'Prin multe ţări frumoase',             nr: '11.12',   songPrevAlfID: '13.14',     songNextAlfID: '13.15'  },
    { titlu: 'Privim prin credinţă în zare',         nr: '13.15',   songPrevAlfID: '11.12',     songNextAlfID: '1.7'    },
    { titlu: 'Privind la bolta-albastră',            nr: '1.7',     songPrevAlfID: '13.15',     songNextAlfID: '9.12'   },
  
    /* * * R - 2 * * */  
  
    { titlu: 'R'},
    { titlu: 'Ridică-ţi astăzi privirea',            nr: '9.12',    songPrevAlfID: '1.7',       songNextAlfID: '6.37'   },
    { titlu: 'Roagă-te fără-ncetare',                nr: '6.37',    songPrevAlfID: '9.12',      songNextAlfID: '10.9'   },
  
    /* * * S - 32 * * */  
  
    { titlu: 'S'},
    { titlu: 'S-a născut un Împărat',                nr: '10.9',    songPrevAlfID: '6.37',      songNextAlfID: '8.18'   },
    { titlu: 'Să am tot ce privirea',                nr: '8.18',    songPrevAlfID: '10.9',      songNextAlfID: '2.21'   },
    { titlu: 'Să cânt să cânt Tu-mi ceri mereu',     nr: '2.21',    songPrevAlfID: '8.18',      songNextAlfID: '6.10'   },
    { titlu: 'Să fie furtună şi valuri şi ploi',     nr: '6.10',    songPrevAlfID: '2.21',      songNextAlfID: '6.38'   },
    { titlu: 'Să fie rugăciunea ta',                 nr: '6.38',    songPrevAlfID: '6.10',      songNextAlfID: '8.19'   },
    { titlu: 'Să mergi pe calea-ngustă',             nr: '8.19',    songPrevAlfID: '6.38',      songNextAlfID: '17.80'  },
    { titlu: 'Să nu lași după mine',                 nr: '17.80',   songPrevAlfID: '8.19',      songNextAlfID: '6.39'   },
    { titlu: 'Să nu se laude cel ce-ncinge arma',    nr: '6.39',    songPrevAlfID: '17.80',     songNextAlfID: '4.28'   },
    { titlu: 'Să nu te temi când vezi in jur',       nr: '4.28',    songPrevAlfID: '6.39',      songNextAlfID: '4.19'   },
    { titlu: 'Să te-asculte Domnu-n ziua de necaz',  nr: '4.19',    songPrevAlfID: '4.28',      songNextAlfID: '13.39'  },
    { titlu: 'Scoală-te popor şi luptă',             nr: '13.39',   songPrevAlfID: '4.19',      songNextAlfID: '13.43'  },
    { titlu: 'Se-aud pașii lui Isus',                nr: '13.43',   songPrevAlfID: '13.39',     songNextAlfID: '17.22'  },
    { titlu: 'Se-aude sus pe cer',                   nr: '17.22',   songPrevAlfID: '13.43',     songNextAlfID: '17.41'  },
    { titlu: 'Secerişul este foarte-aproape',        nr: '17.41',   songPrevAlfID: '17.22',     songNextAlfID: '13.40'  },
    { titlu: 'Se duce viaţa ca un vis',              nr: '13.40',   songPrevAlfID: '17.41',     songNextAlfID: '17.23'  },
    { titlu: 'Se-mplinesc ale Tale cuvinte',         nr: '17.23',   songPrevAlfID: '13.40',     songNextAlfID: '6.11'   },
    { titlu: 'Se va cânta cântarea minunată',        nr: '6.11',    songPrevAlfID: '17.23',     songNextAlfID: '13.41'  },
    { titlu: 'Simţim încet cum harul',               nr: '13.41',   songPrevAlfID: '6.11',      songNextAlfID: '2.22'   },
    { titlu: 'Slăvesc pe Domnul pacea mea',          nr: '2.22',    songPrevAlfID: '13.41',     songNextAlfID: '17.58'  },
    { titlu: 'Smeriți venim, Doamne, la Tine',       nr: '17.58',   songPrevAlfID: '2.22',      songNextAlfID: '17.16'  },
    { titlu: 'Stăm ridicaţi în faţa Ta',             nr: '17.16',   songPrevAlfID: '17.58',     songNextAlfID: '7.12'   },
    { titlu: 'Străbătând în lung şi-n lat',          nr: '7.12',    songPrevAlfID: '17.16',     songNextAlfID: '7.36'   },
    { titlu: 'Suflete eşti gata eşti tu mântuit',    nr: '7.36',    songPrevAlfID: '7.12',      songNextAlfID: '3.5'    },
    { titlu: 'Sunt dator Isuse toată viaţa mea',     nr: '3.5',     songPrevAlfID: '7.36',      songNextAlfID: '13.27'  },
    { titlu: 'Suntem trecători suntem călători',     nr: '13.27',   songPrevAlfID: '3.5',       songNextAlfID: '4.20'   },
    { titlu: 'Sunt numai un vas de lut',             nr: '4.20',    songPrevAlfID: '13.27',     songNextAlfID: '6.12'   },
    { titlu: 'Sunt pe calea Domnului',               nr: '6.12',    songPrevAlfID: '4.20',      songNextAlfID: '2.23'   },
    { titlu: 'Sunt un mesager rătăcitor prin lume',  nr: '2.23',    songPrevAlfID: '6.12',      songNextAlfID: '7.13'   },
    { titlu: 'Sunt un om nevrednic',                 nr: '7.13',    songPrevAlfID: '2.23',      songNextAlfID: '13.26'  },
    { titlu: 'Sunt un pribeag fără de ţară',         nr: '13.26',   songPrevAlfID: '7.13',      songNextAlfID: '13.16'  },
    { titlu: 'Sunt veacuri multe de atunci',         nr: '13.16',   songPrevAlfID: '13.26',     songNextAlfID: '11.17'  },
    { titlu: 'Sus pe deal, cu chip ciudat',          nr: '11.17',   songPrevAlfID: '13.16',     songNextAlfID: '4.21'   },
  
    /* * * Ș - 1 * * */  
  
    { titlu: 'Ș'},
    { titlu: 'Și viaţa ta e ca o barcă',             nr: '4.21',    songPrevAlfID: '11.17',     songNextAlfID: '2.24'   },
  
    /* * * T - 24 * * */  
  
    { titlu: 'T'},
    { titlu: 'Tatăl nostru care eşti în cer',        nr: '2.24',    songPrevAlfID: '4.21',      songNextAlfID: '6.40'   },
    { titlu: 'Te-ai sfărâmat tu vasule-ncercat',     nr: '6.40',    songPrevAlfID: '2.24',      songNextAlfID: '1.8'    },
    { titlu: 'Te-am privit o, Doamne te-am privit',  nr: '1.8',     songPrevAlfID: '6.40',      songNextAlfID: '4.22'   },
    { titlu: 'Te chem din adânc',                    nr: '4.22',    songPrevAlfID: '1.8',       songNextAlfID: '2.34'   },
    { titlu: 'Te înalţ pe tine Domnul meu',          nr: '2.34',    songPrevAlfID: '4.22',      songNextAlfID: '17.72'  },
    { titlu: 'Tineret, la Domnul vino azi ',         nr: '17.72',   songPrevAlfID: '2.34',      songNextAlfID: '17.78'  },
    { titlu: 'Toată încrederea-n Tine-mi pun',       nr: '17.78',   songPrevAlfID: '17.72',     songNextAlfID: '17.35'  },
    { titlu: 'Tot ce am aici pe-acest pământ',       nr: '17.35',   songPrevAlfID: '17.78',     songNextAlfID: '17.31'  },
    { titlu: 'Trăim al vremilor sfârșit',            nr: '17.31',   songPrevAlfID: '17.35',     songNextAlfID: '4.23'   },
    { titlu: 'Trăirea prin credință',                nr: '4.23',    songPrevAlfID: '17.31',     songNextAlfID: '13.47'  },
    { titlu: 'Trâmbița curând răsună',               nr: '13.47',   songPrevAlfID: '4.23',      songNextAlfID: '5.7'    },
    { titlu: 'Trecea Isus prin Galileea',            nr: '5.7',     songPrevAlfID: '13.47',     songNextAlfID: '17.79'  },
    { titlu: 'Tu ai fost locul de-adăpost',          nr: '17.79',   songPrevAlfID: '5.7',       songNextAlfID: '2.27'   },
    { titlu: 'Tu-ai fost mereu de partea mea',       nr: '2.27',    songPrevAlfID: '17.79',     songNextAlfID: '7.26'   },
    { titlu: 'Tu cel fără pace-n suflet',            nr: '7.26',    songPrevAlfID: '2.27',      songNextAlfID: '6.41'   },
    { titlu: 'Tu dar copilul meu',                   nr: '6.41',    songPrevAlfID: '7.26',      songNextAlfID: '2.25'   },
    { titlu: 'Tu din vânturi faci solii',            nr: '2.25',    songPrevAlfID: '6.41',      songNextAlfID: '17.15'  },
    { titlu: 'Tu eşti iubirea din etern',            nr: '17.15',   songPrevAlfID: '2.25',      songNextAlfID: '17.28'  },
    { titlu: 'Tu faci minuni',                       nr: '17.28',   songPrevAlfID: '17.15',     songNextAlfID: '2.26'   },
    { titlu: 'Tu meriţi toată lauda',                nr: '2.26',    songPrevAlfID: '17.28',     songNextAlfID: '9.13'   },
    { titlu: 'Tu mucul care fumegă nu-l stingi',     nr: '9.13',    songPrevAlfID: '2.26',      songNextAlfID: '6.13'   },
    { titlu: 'Tu ne-ai fost tărie',                  nr: '6.13',    songPrevAlfID: '9.13',      songNextAlfID: '17.73'  },
    { titlu: 'Tu știi cât te-am iubit',              nr: '17.73',   songPrevAlfID: '6.13',      songNextAlfID: '7.31'   },
    { titlu: 'Tu tot stai pe mal de prăpăstii',      nr: '7.31',    songPrevAlfID: '17.73',     songNextAlfID: '13.28'  },
  
    /* * * Ț - 2 * * */  
  
    { titlu: 'Ț'},
    { titlu: 'Țara minunată ţara cea de sus',        nr: '13.28',   songPrevAlfID: '7.31',      songNextAlfID: '8.22'   },
    { titlu: 'Ți-am cunoscut iubirea',               nr: '8.22',    songPrevAlfID: '13.28',     songNextAlfID: '6.42'   },
  
    /* * * U - 5 * * */  
  
    { titlu: 'U'},
    { titlu: 'Ucenicii pe Isus l-au întrebat',       nr: '6.42',    songPrevAlfID: '8.22',      songNextAlfID: '15.9'   },
    { titlu: 'Un chip de lut Tu ai făcut',           nr: '15.9',    songPrevAlfID: '6.42',      songNextAlfID: '7.27'   },
    { titlu: 'Un cor îngeresc',                      nr: '7.27',    songPrevAlfID: '15.9',      songNextAlfID: '13.48'  },
    { titlu: 'Universul e în așteptare',             nr: '13.48',   songPrevAlfID: '7.27',      songNextAlfID: '1.9'    },
    { titlu: 'Un nume-mi place-aşa de mult',         nr: '1.9',     songPrevAlfID: '13.48',     songNextAlfID: '10.11'  },
  
    /* * * V - 11 * * */  
  
    { titlu: 'V'},
    { titlu: 'Vestea bună din cer',                  nr: '10.11',   songPrevAlfID: '1.9',       songNextAlfID: '13.29'  },
    { titlu: 'Vine o zi când vom pleca',             nr: '13.29',   songPrevAlfID: '10.11',     songNextAlfID: '17.18'  },
    { titlu: 'Vine ziua nu-i departe',               nr: '17.18',   songPrevAlfID: '13.29',     songNextAlfID: '15.10'  },
    { titlu: 'Vino isus la nuntă',                   nr: '15.10',   songPrevAlfID: '17.18',     songNextAlfID: '4.24'   },
    { titlu: 'Vocea lui se aude',                    nr: '4.24',    songPrevAlfID: '15.10',     songNextAlfID: '2.28'   },
    { titlu: 'Voi cânta în orice vreme',             nr: '2.28',    songPrevAlfID: '4.24',      songNextAlfID: '2.29'   },
    { titlu: 'Voi lăuda în orice vreme',             nr: '2.29',    songPrevAlfID: '2.28',      songNextAlfID: '17.19'  },
    { titlu: 'Vorbim azi mult de-un glorios trecut', nr: '17.19',   songPrevAlfID: '2.29',      songNextAlfID: '16.11'  },
    { titlu: 'Vreau lângă Dumnezeu',                 nr: '16.11',   songPrevAlfID: '17.19',     songNextAlfID: '11.13'  },
    { titlu: 'Vrei tu să scapi',                     nr: '11.13',   songPrevAlfID: '16.11',     songNextAlfID: '17.76'  },
    { titlu: 'Vremurile-s în schimbare',             nr: '17.76',   songPrevAlfID: '11.13',     songNextAlfID: '9.14'   },
  
    /* * * Z - 2 * * */  
  
    { titlu: 'Z'},
    { titlu: 'Zideşte în mine',                      nr: '9.14',    songPrevAlfID: '17.76',     songNextAlfID: '17.38'  },
    { titlu: 'Zidurile nalte de la Ierihon',         nr: '17.38',   songPrevAlfID: '9.14',      songNextAlfID: '17.2'   },
  
    ]

    export default songsListAlf

    const songsListAlfNumber = songsListAlf.map(nr => nr.nr)

    const songsListAlfPrevID = songsListAlf.map(songPrevID => songPrevID.songPrevAlfID)

    const songsListAlfNextID = songsListAlf.map(songNextID => songNextID.songNextAlfID)

    export { songsListAlf, songsListAlfNumber, songsListAlfPrevID, songsListAlfNextID }