function Gama({range})
{console.log(range)
    return(
        <>
            <details>
                <summary
                    className='instrument__chapter__range-title' 
                >
                    <h2 className='instrument__chapter-content__big-title gen__align-text-center'>Acorduri</h2>
                </summary>
                <div className='instrument__chapter-acorduri-content'>
                <img 
                    className='instrument__chapter-content__img'
                    src={`../../../../img/instrumente/pian/${range}.jpg` }
                    alt='Image not found!'
                />
                </div>                       
            </details>
            <details>
                <summary
                    className='instrument__chapter__range-title' 
                >
                    <h2 className='instrument__chapter-content__big-title gen__align-text-center'>Cântări</h2>
                </summary>
                <div className='instrument__chapter-cantari-content'>
                <img 
                    className='instrument__chapter-content__img'
                    src={`../../../../img/instrumente/pian/${range}1.jpg` }
                    alt='Image not found!'
                />
                </div>                       
            </details>
        </>
    )
}

export default Gama