import { useNavigate, useLocation } from "react-router-dom";
import { songsListSec } from '../../Data/list_of_songs_sec'

const LIST_SECTION_HEADER_MAXLEN = 8;
const START_OF_SONGS_POS = 18;

function SecList({listToRender, ignoreSectionNames, onItemClick, setSection})
{
    const navigate = useNavigate();

    function goToSection(section)
    {
        document.getElementById(section).scrollIntoView(true,{behavior: 'smooth' });
        setSection(section)
    }

    return(
        <ul className="list__items">
        {
            listToRender.map((song, index) => {
                if(song.titlu.length > LIST_SECTION_HEADER_MAXLEN)
                {
                    if(song.titlu.substring(0,2) != 'S0' && song.titlu.substring(0,2) != 'S1')
                    {
                        return(
                        <li 
                            key={song.titlu} 
                            onClick = {() => onItemClick(song)}
                            className="list__item"
                        >
                            {song.titlu}
                        </li>
                        )
                    }
                    else 
                    {
                        if(!ignoreSectionNames){
                            return(
                                <li key={song.titlu}
                                    className="list__item"
                                    onClick = {() => goToSection(song.titlu.substring(0,3))}
                                >
                                    {song.titlu}
                                </li>
                            )
                        }  
                    }
                }
                else
                {
                    return(
                        <li 
                            key={song.titlu} 
                            id = {(song.titlu != 'SECȚIUNI') ? song.titlu : 'S'} 
                            className = 'list__letter-header'
                        >
                            {ignoreSectionNames && song.titlu == 'SECȚIUNI' ? '' : song.titlu}
                        </li>)
                }   
            })
        }
        </ul>
    )
}

export default SecList;