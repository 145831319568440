import { NOTESo2, NOTESo3 } from '../../globals/constants'
import Key from './Key/Key'

function Piano({rotateState})
{
    const octave2 = NOTESo2.map((note, index) => {
        return(
            <Key 
                key={index} 
                note={note}
                rotateState={rotateState}
            />
        )
    })

    const octave3 = NOTESo3.map((note, index) => {
        return(
            <Key 
                key={index} 
                note={note}
                rotateState={rotateState}
            />
        )
    })

    return(
        <>
            <section className='cor-piano'>
                <div className='cor-piano__octave'>
                    {octave2}
                </div>
                <div className='cor-piano__octave'>
                    {octave3}
                </div>
            </section>
        </>
    )
}

export default Piano