import { IconContext } from "react-icons";
import { BsFileEarmarkMusic } from 'react-icons/bs'

function ScoreIcon({eyeIcon}) {
    return (
        <>
            <IconContext.Provider value={{className:"score-icon__eye"}}> 
                {eyeIcon}
            </IconContext.Provider>
            <IconContext.Provider value={{className:"score-icon__file"}}> 
                <BsFileEarmarkMusic />
            </IconContext.Provider>
        </>
    )
}

export default ScoreIcon