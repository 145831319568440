import { React, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { useOrientation } from '@uidotdev/usehooks'

import {BsFillPlayCircleFill, BsFillPauseCircleFill, BsFillSkipStartCircleFill, BsFillSkipEndCircleFill } from 'react-icons/bs'
import { RiSpeedUpLine, RiReplay5Line, RiForward5Line } from 'react-icons/ri'
import { MdRepeat } from "react-icons/md";
import { CiPlay1, CiPause1 } from "react-icons/ci";
import { IoPlaySkipForwardOutline, IoPlaySkipBackOutline  } from "react-icons/io5";

function CustomAudioPlayer({currentSong, selectSong, songToLoad}) 
{
  const audioElement = useRef();
  const playerBarRef = useRef();
  const [isPlaying, setIsPlaying] = useState()
  const [playerBarWidth, setPlayeBarWidth] = useState()
  const [audioMMSSTime, setAudioMMSSTime] = useState({
    current: '00:00',
    duration : '00:00'
  })
  const [repeatTimes, setRepeatTimes] = useState(0)
  const orientation = useOrientation()

  function handleOnAudioLoad()
  {
    const duration = audioElement.current.duration
    const durationTime = new Date(duration * 1000).toISOString().slice(14,19)
    setAudioMMSSTime({current: '00:00', duration: durationTime})
    setIsPlaying(false)
    setPlayeBarWidth(0)
  }

  function playAudio(play_s)
  {
    setIsPlaying(play_s)
    if(audioElement)
    {
      if(play_s)
      {
        audioElement.current.play();
      }
      else
      {
        audioElement.current.pause()
      }
    }
  }

  function updateProgressBar(e)
  {
    const width = playerBarRef.current.clientWidth;
    const offset = e.nativeEvent.offsetX;
    const barProgress = offset / width;
    const currentWidth = barProgress * audioElement.current.duration;

    audioElement.current.currentTime = currentWidth;
    
    setPlayeBarWidth((currentWidth / audioElement.current.duration) * 100)
  }

  function changeAudioSpeed()
  {
    if(isPlaying)
    {
      audioElement.current.playbackRate -= 0.25; 

      if(audioElement.current.playbackRate < 0.5)
      {
        audioElement.current.playbackRate = 1;
      }
    }
  }

  function handleTimeUpdate()
  {
    let current, duration, currentTime, durationTime;
    if(isPlaying)
    {
      current= audioElement.current.currentTime
      duration = audioElement.current.duration
      currentTime = new Date(current * 1000).toISOString().slice(14,19)
      durationTime = new Date(duration * 1000).toISOString().slice(14,19)

      setAudioMMSSTime({current: currentTime, duration : durationTime})
      setPlayeBarWidth(current / duration * 100)
    }
  }

  function handleSongRepeat()
  {
    if(repeatTimes < 2)
    {
      setRepeatTimes(repeatTimes+1);
    }
    else
    {
      setRepeatTimes(0)
    }
  }

  function handleOnSongEnded()
  {
    if(repeatTimes)
    {
      setRepeatTimes(repeatTimes-1)
      audioElement.current.play();
    }
    else
    {
      setIsPlaying(false);
    }
    
    setPlayeBarWidth(0);
    setAudioMMSSTime({current: '00:00', duration: audioMMSSTime.duration})
  }

  return (
    <>
      <audio
        src= {songToLoad}
        ref = {audioElement}
        onTimeUpdate={handleTimeUpdate}
        onLoadedMetadata={handleOnAudioLoad}
        onEnded={handleOnSongEnded}
      />
      {
        orientation.type == 'portrait-primary'
        ? <div className='player'>
            <hr />
            <section className='player-text'>
              <span className='player-text__song-number'>{currentSong.nr}</span>
              <h3 className='player-text__song-title'>{currentSong.titlu}</h3>
              <span className='player-text__song-range'>{currentSong.gama}</span>
            </section>
            
            <section className='player-bar' onClick={updateProgressBar} ref={playerBarRef}>
                  {
                      audioElement.current
                      ? <div 
                          className='player-bar__seek-bar'
                          style={{width: `${playerBarWidth}%`}}
                        ></div>
                      : <div 
                          className='player-bar__seek-bar'
                          style={{width:'0px'}}
                        ></div>
                  }
                      <span className='player-bar__timing'>{audioMMSSTime.duration}</span>
                      <span className='player-bar__timing'>{audioMMSSTime.current}</span>
            </section>       
                  
            <section className='player-ctrl'>
                  <div className="player-ctrl__repeat" onClick={handleSongRepeat}>
                      <MdRepeat className='player-ctrl__secondary-btn' />
                      <span className="player-ctrl__repeat-times">{repeatTimes}</span>
                  </div>
                  <RiReplay5Line className='player-ctrl__backward' onClick={() => {if(isPlaying) audioElement.current.currentTime -= 5}}/>
                  <IoPlaySkipBackOutline  
                      className='player-ctrl__previous' 
                      onClick={() => selectSong(currentSong.index-1)}
                  />
                  {
                      isPlaying 
                      ? <CiPause1 className='player-ctrl__play-pause' onClick={() => playAudio(false)}/>

                      : <CiPlay1 className='player-ctrl__play-pause' onClick={() => playAudio(true)}/>
                  }
                  <IoPlaySkipForwardOutline className='player-ctrl__next' onClick={() => selectSong(currentSong.index+1)}/>        
                  <RiForward5Line  className='player-ctrl__forward' onClick={() => {if(isPlaying) audioElement.current.currentTime += 5}}/>
                  
                  <div className="player-ctrl__playback" onClick={changeAudioSpeed}>
                      <RiSpeedUpLine className='player-ctrl__secondary-btn' />
                      <span className="player-ctrl__playback-speed">
                          {isPlaying ? audioElement.current.playbackRate : '1'}
                      </span>
                  </div>
            </section>
          </div>
        : <div className='player__landscape'>
            <section className='player-ctrl__landscape'>
              <RiReplay5Line className='player-ctrl__backward' onClick={() => {if(isPlaying) audioElement.current.currentTime -= 5}}/>
              {
                  isPlaying 
                  ? <CiPause1 className='player-ctrl__play-pause' onClick={() => playAudio(false)}/>

                  : <CiPlay1 className='player-ctrl__play-pause' onClick={() => playAudio(true)}/>
              }
              <RiForward5Line  className='player-ctrl__forward' onClick={() => {if(isPlaying) audioElement.current.currentTime += 5}}/>
              <div className="player-ctrl__playback" onClick={changeAudioSpeed}>
                  <RiSpeedUpLine className='player-ctrl__secondary-btn' />
                  <span className="player-ctrl__playback-speed">
                      {isPlaying ? audioElement.current.playbackRate : '1'}
                  </span>
              </div>
            </section>
            <section className='player-bar__landscape' onClick={updateProgressBar} ref={playerBarRef}>
                  {
                      audioElement.current
                      ? <div 
                          className='player-bar__seek-bar'
                          style={{width: `${playerBarWidth}%`}}
                        ></div>
                      : <div 
                          className='player-bar__seek-bar'
                          style={{width:'0px'}}
                        ></div>
                  }
                      {/* <span className='player-bar__timing'>{audioMMSSTime.duration}</span>
                      <span className='player-bar__timing'>{audioMMSSTime.current}</span> */}
            </section> 
          </div>
      }
    </>
  )
}

export default CustomAudioPlayer
