import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import axios from "axios";

import songsListAlf from "../../Data/list_of_songs_alf";

import UList from "../../components/UList/UList";
import SecList from "../../components/SecList/SecList";
import Navbar from "../../components/Navbar/Navbar";
import { LITERE_ALFABET } from "../../globals/constants";
import { songsListSec } from "../../Data/list_of_songs_sec";

let titluCantariDeRepetat = null;
const GL_SEC = 0;
const GL_ALF = 1;
const GL_REP = 2;
//let listType = null;

function GL_initComponent()
{
    //scrollToLitera();
    //log_msg()
}

// function log_msg()
// {
//   if(document.getElementById('list__items'))
//   {
//     console.log(document.getElementById('list__items').scrollTop)
//   }
// }

const LIST_TYPE = ['Secțiuni', 'Alfabetic', 'Repetiții']

function SongsList()
{
    //const { listType } = useParams();
    const location = useLocation();
    const [listaDeRepetat, setListaDeRepetat] = useState([])
    const [searchBarState, setSearchBarState] = useState(false)
    const [searchExpresion, setSearchExpresion] = useState([])
    const [currentSection, setCurentSection] = useState(location.state ? location.state.section : 'S')
    const [filteredList, setFilteredList] = useState([]);
    const [listType, setListType] = useState(location.state ? location.state.listType : LIST_TYPE[GL_SEC]);
    const [listTypeDrpdwnActive, setListTypeDrpdwnActive] = useState(false)
    const navigate = useNavigate()
    const searchBarRef = useRef();
    const letterSelectRef = useRef();
    const listHeadersRefs = useRef([]);

    const listTypeDrpdwnStyle = {
        transition: "all .5s ease-in-out",
        opacity: listTypeDrpdwnActive ? 1 : 0,
        marginRight: listTypeDrpdwnActive ? '0px' : '-80px',
    }

    const listTypeDrpdwnComp =  
        listTypeDrpdwnActive 
            ? <div className="list__type-bg" onClick={() => setListTypeDrpdwnActive(false)} style={listTypeDrpdwnStyle}> 
                <div className="list__type-dropdown" style={listTypeDrpdwnStyle}>
                    {
                        LIST_TYPE.map(type => {
                            return <div key={type} onClick={handleListType}>{type}</div> 
                        })
                    }
                </div>
              </div>
            : <></>

    //let alfLetter = location.state ? location.state.section : 'A';
    function scrollToLitera(litera)
    {
        let litera_value = null;
        let literaIndex = null;

        if(litera){
            litera_value = litera;
        }
        else{
            litera_value = letterSelectRef.current.value;
        }

        for(let i=0; i<= LITERE_ALFABET.length; i++)
        {
            if(LITERE_ALFABET[i] == litera_value){
                literaIndex = i;
            }
        }

        if(listHeadersRefs.current.length != 0){
            console.log(currentSection, literaIndex, listHeadersRefs.current[literaIndex])
            listHeadersRefs.current[literaIndex].scrollIntoView();
        }
    }

    function handleListType(e)
    {
        setListType(e.target.innerText)
        setListTypeDrpdwnActive(false);
    }

    function openLyrics(objSong, index)
    {
        let selectedSongSection, completeSection;

        if(listType == LIST_TYPE[GL_SEC]){
            selectedSongSection = objSong.nr.split('.')[0] 

            if(Number(selectedSongSection) < 10){
                completeSection = 'S0'+ selectedSongSection
            }else{
                completeSection = 'S'+ selectedSongSection
            }
        }else{
            completeSection = objSong.titlu.substring(0,1)
        }
                   
        navigate(`../lyrics/${objSong.nr}`, {
            state: {
                listType: listType,
                index: index,
                section: completeSection
            }
        })
    }

    function searchForSong(listToFilter)
    {
        const searchValue = searchBarRef.current.value;
        
        const filteredSongs = listToFilter.filter(song => {
            return song.titlu.toLowerCase().includes(searchValue.toLowerCase())
        })

        setSearchExpresion(searchValue);
        setFilteredList(filteredSongs);
    }

    function handleSearchBar(state)
    {
        setSearchBarState(state);
        if(listType == LIST_TYPE[GL_ALF]){
            letterSelectRef.current.value = 'A';
        }

        document.querySelector('.list__items').scrollIntoView(true);
        if(!searchBarRef){
            searchBarRef.current.focus()
        }
    }

    useEffect(() => {
        let topListElm = document.getElementsByTagName("li")[0];
        if(topListElm){
            topListElm.scrollIntoView()
        }
        setFilteredList(listType == LIST_TYPE[GL_SEC] ? songsListSec : songsListAlf)
    }, [listType])


    useEffect(() =>
    {
        axios.get("../../data/cantari_repetitii.json")
        .then((response) => {
            setListaDeRepetat(response.data['cantari']);
            titluCantariDeRepetat = response.data['headerCantari'];
        })
        .catch(error  => {
            console.log(error)
        })

        if(currentSection)
        {
            let section_id;

            section_id = document.getElementById(currentSection)
            
            if(listType == LIST_TYPE[GL_ALF]){
                letterSelectRef.current.value = currentSection;
                setTimeout(() => scrollToLitera(currentSection), 0.1);
            }
            
            if(listType == LIST_TYPE[GL_SEC]){
                section_id.scrollIntoView(true,{behavior: 'smooth' });  
            }
        }

        //if(listType == 'alf') GL_initComponent(alfLetter);
    }, [])

    switch(listType)
    {
        case LIST_TYPE[GL_SEC]:
            //listType = 'seclist'
            return(
                <main className="list">
                    {listTypeDrpdwnComp}
                    <div className="list__wrapper">
                        {
                            searchBarState ?
                                <input 
                                    className="list__search-bar" 
                                    type="search" 
                                    value={searchExpresion}
                                    placeholder="Cântare sau nr..."
                                    onChange={() => searchForSong(songsListSec)}
                                    ref={searchBarRef}
                                />
                                : <></>
                        }
                        <SecList 
                            listToRender={searchBarState ? filteredList : songsListSec} 
                            onItemClick={openLyrics} 
                            ignoreSectionNames={searchBarState} 
                            setSection={setCurentSection}/>
                    </div>
                    <div className="navigation">
                        <Navbar 
                            navType={LIST_TYPE[GL_SEC]}
                            searchBarState={searchBarState} 
                            handleSearchBar={handleSearchBar}
                            listTypeDrpdwnActive={listTypeDrpdwnActive}
                            setListTypeDrpdwnActive={setListTypeDrpdwnActive}
                        />
                    </div> 
                </main>
            )
        case LIST_TYPE[GL_ALF]:
           //listType = 'alflist'
           return (
                <main className="list">
                    {listTypeDrpdwnComp}
                    <div className="list__wrapper">
                        {
                            searchBarState ?
                                <input 
                                    className="list__search-bar" 
                                    type="search" 
                                    value={searchExpresion}
                                    placeholder="Cântare sau nr..."
                                    onChange={() => searchForSong(songsListAlf)}
                                    ref={searchBarRef}
                                />
                                : <></>
                        }
                        
                        <select 
                            className="list__letter-select" 
                            onChange={() => scrollToLitera(0)}
                            ref = {letterSelectRef}
                        >
                            {
                                LITERE_ALFABET.map(litera => {
                                    return (
                                        <option key={litera}> {litera} </option>
                                    )
                                })
                            }
                        </select>
                        <UList 
                            listToRender={filteredList} 
                            onItemClick={openLyrics}
                            ref={listHeadersRefs}
                        />
                    </div>

                    <div className="navigation">
                        <Navbar 
                            navType={LIST_TYPE[GL_ALF]} 
                            searchBarState={searchBarState} 
                            handleSearchBar={handleSearchBar}
                            listTypeDrpdwnActive={listTypeDrpdwnActive}
                            setListTypeDrpdwnActive={setListTypeDrpdwnActive}
                        />
                    </div> 
                </main>
           )
        case LIST_TYPE[GL_REP]:
            //listType = 'replist'
            return(
                <main className="list">
                    {listTypeDrpdwnComp}
                    <div className="list__wrapper">
                        <h2 className = 'list__rep-header'>
                            {titluCantariDeRepetat}
                        </h2>
                        <UList 
                            listToRender={listaDeRepetat} 
                            onItemClick={openLyrics}
                        />
                    </div>
                    <div className="navigation">
                        <Navbar 
                            navType={LIST_TYPE[GL_REP]}
                            listTypeDrpdwnActive={listTypeDrpdwnActive}
                            setListTypeDrpdwnActive={setListTypeDrpdwnActive}
                        />
                    </div> 
                </main>
            )

        default:
            break;
    }
}

export default SongsList;