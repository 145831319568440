/*
*** Author      : Daniel Suciu
*** Description : This component contains audio and video songs
***               of Tineret Sion TGM
*/

import { React, useEffect, useState } from 'react'
import axios from 'axios'

import Navbar from '../../components/Navbar/Navbar'
import CustomAudioPlayer from '../../components/CustomAudioPlayer/CustomAudioPlayer'
import UList from '../../components/UList/UList'

import { CiVideoOn } from "react-icons/ci";

function ARHIVA_Comp() {
    const [audioSongs, setAudioSongs] = useState([])
    const [videoSongs, setVideoSongs] = useState([])
    const [currentSong, setCurrentSong] = useState({
        index: 0,
        nr: '1',
        titlu: 'Acum e timpul pe sfârșit',
        gama: '---'
    });
    const [songToLoad, setSongToLoad] = useState('')
    const [audioList, setAudioList] = useState(true)

    useEffect(() => {
        axios.get("../../data/audio_data.json")
        .then((response) => {
            let songs = response.data.map((item, index) => 
            {
                return {
                    index: index,
                    nr: item.nr,
                    titlu: item.titlu,
                    gama: item.data
                }
            });
            setAudioSongs(songs);
        })
        .catch(error  => {
            console.log(error)
        })

        axios.get("../../data/video_list.json")
        .then((response) => {
            let songs = response.data.map((item, index) => 
            {
                return {
                    index: index,
                    nr: item.nr,
                    titlu: item.titlu,
                    gama: item.data
                }
            });
            setVideoSongs(songs);
        })
        .catch(error  => {
            console.log(error)
        })
    }, [])

    useEffect(() => {
        if(audioList){
            if(currentSong.index == 0){
                selectArhivaSong(currentSong.index)
            }
            else{
                selectArhivaSong(audioSongs[0].index)
            }
        }
        else{
            selectArhivaSong(videoSongs[0].index)
        }
    }, [audioList])

    function selectArhivaSong(...args)
    {
        let arhivaType = audioList ? audioSongs : videoSongs;
        let arhivaPath;

        if(args.length == 1)
        {
            if(args[0] >= 0 && args[0] < arhivaType.length)
            {
                const songToSelect = arhivaType[args[0]]
                arhivaPath = audioList ? `audio/${songToSelect.nr}.mp3` : `video/${songToSelect.nr}.mp4`;
                setCurrentSong(songToSelect)
                setSongToLoad(arhivaPath)
            }
        }
        else
        {
            const songToSelect = arhivaType[args[1]]
            arhivaPath = audioList ? `audio/${songToSelect.nr}.mp3` : `video/${songToSelect.nr}.mp4`;
            setCurrentSong(songToSelect)
            setSongToLoad(arhivaPath)
        }
    }

    if(audioList)
    {
        return (
            <main className='audio'>
                <UList 
                    listToRender={audioSongs} 
                    onItemClick={selectArhivaSong}
                />

                <section className='audio__tools'>
                    <CustomAudioPlayer 
                        currentSong={currentSong}
                        selectSong={selectArhivaSong}
                        songToLoad={songToLoad}
                    />
                    <div className='navigation'>
                        <Navbar navType={'AUDIO'} audioList={audioList} setAudioList={setAudioList} />
                    </div>
                </section>
            </main>
        )
    }
    else
    {
      return (
        <main className='video'>
            <section className='video__player'>
                <video controls width="400" key={currentSong.nr}>
                    <source src={songToLoad} type="video/mp4" />
                </video>
                <div className='video__info'>
                    <i className='video__info-icon gen__svg-alignment'>
                        <CiVideoOn />
                    </i>
                    <h3>{currentSong.titlu}</h3>
                    <h5>{currentSong.gama}</h5>
                </div>
            </section>
            
            <UList 
                listToRender={videoSongs} 
                onItemClick={selectArhivaSong}
            />
            
            <div className='navigation'>
                <Navbar navType={'VIDEO'} audioList={audioList} setAudioList={setAudioList} />
            </div>
        </main>
      )
    }
}

export default ARHIVA_Comp
